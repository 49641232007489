import { GameApi } from 'urban-challenger-sdk';
import type { LobbyType } from './interface';

export class BaseLobby implements LobbyType {
    // protected unsubscribe;

    constructor(protected rootElement: HTMLElement, protected sdk: GameApi) {
        // this.unsubscribe = this.sdk.subscribe(this.onStateUpdate);
    }

    public getElement(): HTMLElement {
        return this.rootElement;
    }

    // protected onStateUpdate = (state: ApiState) => {};

    public show(): void {
        this.rootElement.classList.remove('hidden');
        this.rootElement.setAttribute('aria-hidden', 'false');
    }

    public hide(): void {
        this.rootElement.classList.add('hidden');
        this.rootElement.setAttribute('aria-hidden', 'true');
    }
}
