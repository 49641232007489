import { createElement } from './create-element';
import '../scss/toasts.scss';

const classes = {
    base: 'gh-toast',
    hidden: 'gh-toast--hidden',
    dismissed: 'gh-toast--dismissed',
    containerDismissed: 'gh-toast__container--dismissed',
    close: 'gh-toast__close',
    content: 'gh-toast__content',
};

type ToastType = 'success' | 'info' | 'warn' | 'error';

class Toasts {
    protected container: HTMLElement;
    protected queue: Set<Toast> = new Set();

    constructor() {
        this.container = createElement({
            classNames: ['gh-toasts'],
        });

        document.body.appendChild(this.container);
    }

    protected onToastRemoved = (t: Toast) => {
        this.queue.delete(t);
        if (this.queue.size) {
            this.queue.values().next().value.hasFocus();
        }
    };

    public make(
        content: string,
        type: ToastType | null = 'info',
        {
            heading = null,
            dismissible = true,
            timeoutSeconds = 10,
        }: {
            heading?: string | null;
            dismissible?: boolean;
            timeoutSeconds?: number | null;
        } = {}
    ): Toast {
        const t = new Toast(
            this.container,
            this.onToastRemoved,
            content,
            type || 'info',
            heading,
            dismissible,
            timeoutSeconds
        );
        if (!this.queue.size) {
            t.hasFocus();
        }
        this.queue.add(t);

        return t.show();
    }
}

class Toast {
    protected element: HTMLElement;
    protected contentElement: HTMLElement;
    protected timer: number = 0;
    protected closeButton: HTMLElement | null = null;

    constructor(
        container: HTMLElement,
        protected onRemoved: (t: Toast) => void,
        protected content: string,
        protected type: ToastType = 'info',
        protected heading: string | null = null,
        protected readonly dismissible: boolean = true,
        protected readonly timeoutSeconds: number | null = 10
    ) {
        this.element = createElement({
            type: 'ASIDE',
            classNames: [classes.base, classes.hidden, `gh-toast--${type}`],
            attributes: {
                'aria-hidden': 'true',
            },
        });

        this.contentElement = this.createContent();

        container.appendChild(this.element);
    }

    public show(): this {
        this.element.classList.remove(classes.hidden);
        this.element.setAttribute('aria-hidden', 'false');

        if (this.dismissible && this.closeButton) {
            this.closeButton.addEventListener('click', (e) => {
                e.preventDefault();
            });
        }

        return this;
    }

    public hasFocus(): void {
        if (this.timeoutSeconds) {
            this.timer = window.setTimeout(() => {
                this.destroy();
            }, this.timeoutSeconds * 1000);
        }
    }

    public hide(): this {
        this.contentElement.addEventListener('transitionend', () => {
            this.element.classList.add(classes.hidden);
        });
        this.contentElement.classList.add(classes.containerDismissed);
        this.element.setAttribute('aria-hidden', 'true');

        return this;
    }

    public destroy = async (): Promise<void> => {
        if (this.timer) {
            window.clearTimeout(this.timer);
        }

        return new Promise<void>((res) => {
            this.element.addEventListener('transitionend', () => {
                this.destroyElement();
                res();
            });
            this.hide();
        });
    };

    protected destroyElement() {
        if (this.timer) {
            window.clearTimeout(this.timer);
        }

        this.closeButton = null;
        this.element.remove();
        this.element = null; // tslint-ignore
        this.onRemoved(this);
    }

    protected createContent(): HTMLElement {
        const contentElement = createElement({
            classNames: ['gh-toast__container'],
            parent: this.element,
        });

        if (this.dismissible) {
            this.closeButton = createElement({
                type: 'BUTTON',
                classNames: [classes.close],
                parent: contentElement,
                content: '×',
                attributes: {
                    type: 'button',
                },
            });
            this.closeButton.addEventListener('click', this.destroy);
        }

        const container = createElement({
            classNames: [classes.content],
            parent: contentElement,
        });

        if (this.heading) {
            createElement({
                type: 'H1',
                content: this.heading,
                parent: container,
            });
        }

        createElement({
            type: 'P',
            classNames: [classes.content],
            parent: container,
            content: this.content,
        });

        return contentElement;
    }
}

export const toasts = new Toasts();
