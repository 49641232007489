import type { JsonTeam } from './model/team';
import { Team } from './model/team';

export enum UserStatus {
    INITIAL,
    UNIDENTIFIED,
    LOBBY,
    OWNER_LOBBY,
    TEAM_LOBBY_LEADER,
    TEAM_LOBBY,
    PLAYING,
    GAME_ENDED,
}

export interface UserStatusResponseBaseObject {
    id: string;
    type: string;
    ownsGame: boolean;
    team: null | JsonTeam;
    isPlaying: boolean;
    teams?: JsonTeam[];
    maxDevices?: number;
    urls: Record<string, string>;
    playerCount: number;
    version?: number;
}

export interface UserStatusResponseObject_V1
    extends UserStatusResponseBaseObject {
    gameEnded: boolean;
}

export interface UserStatusResponseObject_V3
    extends UserStatusResponseBaseObject {
    game: {
        ended: boolean;
        moderated: boolean;
        resultsDeclared: boolean;
    };
}

export type UserStatusResponseObject =
    | UserStatusResponseObject_V1
    | UserStatusResponseObject_V3;

export interface ApiState {
    userId: string | null;
    status: UserStatus;
    team: Team | null;
    score: number;
    teams: Team[] | null;
    maxDevices: number | null;
    urls: Record<string, string> | null;
    playerCount: number;
    ownsGame: boolean;
    game: {
        ended: boolean;
        moderated: boolean;
        resultsDeclared: boolean;
    };
}

export const DEFAULT_STATE: ApiState = {
    userId: null,
    status: UserStatus.INITIAL,
    team: null,
    score: 0,
    teams: null,
    maxDevices: null,
    urls: null,
    playerCount: 0,
    ownsGame: false,
    game: {
        ended: false,
        moderated: false,
        resultsDeclared: false,
    },
};

export function updateStateFromApi(
    statusResponse: UserStatusResponseObject | null
): UserStatus {
    const status =
        (statusResponse !== null && determineUserStatus(statusResponse)) ||
        UserStatus.UNIDENTIFIED;
    const team =
        (statusResponse &&
            statusResponse.team &&
            Team.fromJson(statusResponse.team)) ||
        null;

    this.version.update(statusResponse.version);

    if (statusResponse) {
        this.store.update((state) => {
            const newState = {
                ...state,
                userId: statusResponse.id,
                status,
                team,
                score: team?.score || 0,
                teams:
                    (statusResponse?.teams &&
                        Team.fromJsonArray(statusResponse.teams)) ||
                    null,
                maxDevices: statusResponse?.maxDevices || null,
                urls: statusResponse.urls,
                playerCount: statusResponse.playerCount,
                ownsGame: statusResponse.ownsGame,
            };

            if ('game' in statusResponse) {
                newState.game = {
                    ...state.game,
                    ...statusResponse.game,
                };
            } else {
                newState.game = {
                    ...state.game,
                    ended: statusResponse.gameEnded,
                };
            }

            return newState;
        });
    }

    return status;
}

function determineUserStatus(userObject: UserStatusResponseObject): UserStatus {
    if (userObject.isPlaying) {
        return UserStatus.PLAYING;
    }

    if (
        ('game' in userObject && userObject.game.ended) ||
        ('gameEnded' in userObject && userObject.gameEnded)
    ) {
        return UserStatus.GAME_ENDED;
    }

    if (userObject.team !== null) {
        if (userObject.team.leader === userObject.id) {
            return UserStatus.TEAM_LOBBY_LEADER;
        }
        return UserStatus.TEAM_LOBBY;
    }

    if (userObject.ownsGame) {
        return UserStatus.OWNER_LOBBY;
    }

    return UserStatus.LOBBY;
}
