import type { GameApi, ApiState } from 'urban-challenger-sdk';

export class ScoreBoard {
    private readonly scoreDisplay: HTMLElement;
    private readonly completedTasksDisplay: HTMLElement;
    private initialized: boolean = false;

    constructor(
        private readonly element: HTMLElement,
        private readonly sdk: GameApi
    ) {
        const scoreDisplay = <HTMLElement>(
            element.querySelector('[data-game-score-display]')
        );
        const completedTasksDisplay = <HTMLElement>(
            element.querySelector('[data-game-challenges-display]')
        );

        if (!(scoreDisplay && completedTasksDisplay)) {
            throw new Error(
                'The scoreboard must contain both a score and a challenges display!'
            );
        }

        this.scoreDisplay = scoreDisplay;
        this.completedTasksDisplay = completedTasksDisplay;

        this.sdk.subscribe(this.update);
    }

    public setScore(score: number) {
        this.scoreDisplay.innerText = score.toString(10);
    }

    public setCompletedTasks(taskCount: number) {
        this.completedTasksDisplay.innerText = taskCount.toString(10);
    }

    private update = ({ score, team }: ApiState) => {
        if (score !== parseInt(this.scoreDisplay.innerText, 10)) {
            this.setScore(score);
        }

        let taskCount = 0;

        if (team?.tasks) {
            taskCount = Object.values(team.tasks).filter((task) => {
                return task.state === 'approved';
            }).length;

            if (
                taskCount !== parseInt(this.completedTasksDisplay.innerText, 10)
            ) {
                this.setCompletedTasks(taskCount);
            }
        }

        if (team && !this.initialized) {
            this.element.classList.add('initialized');
            this.initialized = true;
        }
    };
}
