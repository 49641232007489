import type { ApiState, GameApi, Team } from 'urban-challenger-sdk';

import { createElement, UserStatus } from 'urban-challenger-sdk';
import { EnhancedElement } from '@gebruederheitz/energize';

import { confirm } from '../util/confirm';
import { localize } from '../util/i18n/localize';

export class TeamInvitation extends EnhancedElement<
    HTMLElement,
    GameApi,
    ApiState
> {
    protected static templates: Map<
        Element | Document,
        EnhancedElement<HTMLElement>
    > = new Map();

    protected readonly memberCount: EnhancedElement<HTMLElement> | null = null;
    protected readonly ownerJoinButton: EnhancedElement<HTMLElement> | null =
        null;
    protected joinUrl: URL | null = null;
    protected childrenInitialized = false;
    protected mounted = false;

    /**
     * Find or create a template and clone it into a new TeamInvitation object.
     *
     * @param {Array<Element|Document>} templateSearchRoots Where to search for
     *                      the template element, in order. If no template can
     *                      be found inside the first array element, the search
     *                      is continued in the second one etc.
     *                      If none of the roots contain a
     *                      [data-game-component="invite-template"], a default
     *                      fallback template is created and used.
     * @param team
     * @param sdk
     */
    public static fromTemplate(
        templateSearchRoots: Array<Element | Document | null>,
        team: Team,
        sdk: GameApi
    ): TeamInvitation {
        let template;

        for (let root of templateSearchRoots) {
            if (!root) {
                continue;
            }

            if (TeamInvitation.templates.has(root)) {
                template = TeamInvitation.templates.get(root);
                break;
            }
            const elementFound = root.querySelector<HTMLElement>(
                '[data-game-component="invite-template"]'
            );

            if (!elementFound) {
                continue;
            }

            template = new EnhancedElement(elementFound);
            TeamInvitation.templates.set(root, template);
        }

        if (!template) {
            const template = TeamInvitation.createFallbackTemplate();
            TeamInvitation.templates.set(templateSearchRoots[0], template);
        }

        return template.cloneInto(TeamInvitation, team).connect(sdk);
    }

    /**
     * If the page does not provide a valid template for team invites, we create
     * a default tree.
     */
    protected static createFallbackTemplate(): EnhancedElement<any> {
        const container = EnhancedElement.fromNewDiv().setAttribute(
            'data-game-component',
            'invite-template'
        );
        const containerElement = container.getElement();

        // Readonly input containing the code
        createElement<HTMLDivElement>({
            type: 'DIV',
            classNames: ['team-invite', 'team-invite--code'],
            attributes: {
                'data-game-component': 'invite-code',
            },
            parent: containerElement,
        });
        // Readonly input containing direct join URL
        createElement<HTMLDivElement>({
            type: 'DIV',
            classNames: ['team-invite', 'team-invite--url'],
            attributes: {
                'data-game-component': 'invite-url',
            },
            parent: containerElement,
        });
        // Email share link
        createElement<HTMLAnchorElement>({
            type: 'A',
            classNames: [
                'w-button',
                'button',
                'btn',
                'btn-primary',
                'team-invite--mail',
            ],
            content: 'Invite your friends via email',
            attributes: {
                'data-game-component': 'invite-email',
            },
            parent: containerElement,
        });

        return container;
    }

    protected constructor(element: HTMLElement, protected team: Team) {
        super(element);

        const memberCountElement = this.element.querySelector<HTMLElement>(
            '[data-game-component="member-count"]'
        );
        if (memberCountElement) {
            this.memberCount = new EnhancedElement<HTMLElement>(
                memberCountElement
            );
        }

        this.ownerJoinButton = this.findAndWrap(
            '[data-game-component="join-team"]'
        );
        this.ownerJoinButton?.on('click', this.onJoinButtonClick);

        this.updateElements();
    }

    protected onJoinButtonClick = async (e: MouseEvent) => {
        e.preventDefault();
        const { teams } = await this.store.getState();

        if (!teams) {
            return;
        }

        const teamsWithoutLeader = teams.filter((t) => !t.leader);
        if (
            teamsWithoutLeader.length > 1 ||
            (teamsWithoutLeader.length === 1 &&
                teamsWithoutLeader[0].code !== this.team.code)
        ) {
            if (!(await confirm('confirmation.owner.join'))) {
                return;
            }
        }

        await this.store.joinTeam(this.team.code);
    };

    protected override onStateUpdate({
        team,
        teams = [],
        status,
        urls,
    }: ApiState): void {
        let thisTeam;
        if (team && team.code === this.team.code) {
            thisTeam = team;
        } else if (teams) {
            thisTeam = teams.find((e) => e.code === this.team.code);
        }

        if (thisTeam && thisTeam !== this.team) {
            this.team = thisTeam;
            this.updateElements();
        }

        if (this.ownerJoinButton) {
            if (team) {
                this.ownerJoinButton.hide();
            } else if (status === UserStatus.OWNER_LOBBY) {
                this.ownerJoinButton.show();
            }
        }

        if (urls && !this.joinUrl) {
            this.joinUrl = new URL(urls.baseDeeplink + urls.lobby);
        }

        if (!this.childrenInitialized && this.mounted) {
            this.parseChildren();
            this.childrenInitialized = true;
        }
    }

    public override appendTo(element): this {
        super.appendTo(element);
        this.setAttribute('data-game-component', 'team-invite');
        if (!this.childrenInitialized && this.joinUrl) {
            this.parseChildren();
            this.childrenInitialized = true;
        }
        this.mounted = true;
        this.show();

        return this;
    }

    protected updateElements(): void {
        if (!this.element) {
            return;
        }

        this.element.dataset.hasMembers = (
            this.team.members.length !== 0
        ).toString();
        this.element.dataset.hasLeader = (this.team.leader !== null).toString();

        if (this.memberCount) {
            this.memberCount.content(this.team.members.length.toString());
        }
    }

    protected parseChildren(): void {
        this.joinUrl.searchParams.set('uc-team', this.team.code);

        const codeDisplay = this.findAndWrap(
            '[data-game-component="invite-code"]'
        );
        if (codeDisplay) {
            // codeDisplay.setAttribute('value', this.team.code);
            codeDisplay.content(this.team.code);
        }

        const urlDisplay = this.findAndWrap(
            '[data-game-component="invite-url"]'
        );
        if (urlDisplay) {
            urlDisplay.content(this.joinUrl.toString());
        }

        const emailLink = this.findAndWrap(
            '[data-game-component="invite-email"]'
        );
        if (emailLink) {
            const subjectLine = localize('mail.invite.subject');
            emailLink.setAttribute(
                'href',
                `mailto:?subject=${encodeURIComponent(
                    subjectLine
                )}&body=${encodeURIComponent(this.joinUrl.toString())}`
            );
        }

        const qrCodes = this.element.getElementsByTagName('uc-qr-code');
        if (window.customElements.get('uc-qr-code') && qrCodes.length) {
            Array.from(qrCodes).forEach((qr) => {
                qr.setAttribute('text', this.joinUrl.toString());
            });
        }
    }
}
