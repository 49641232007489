import { GameApi } from 'urban-challenger-sdk';
import _difference from 'lodash-es/difference';
import { EnhancedElement } from '@gebruederheitz/energize';

import { BaseLobby } from './base';

export class RedirectLobby extends BaseLobby {
    protected previousUrls = {};
    protected redirectButton: EnhancedElement<HTMLAnchorElement> | null;
    protected redirectTarget: string = '#';

    constructor(protected rootElement: HTMLElement, protected sdk: GameApi) {
        super(rootElement, sdk);
        this.redirectButton = EnhancedElement.fromSelector(
            '[data-game-component="game-start-link"]'
        );

        this.redirectButton.on('click', async (e) => {
            e.preventDefault();
            await GameApi.refreshMemberstack();
            window.location.href = this.redirectTarget;
        });

        this.sdk.subscribe(({ urls }) => {
            if (
                urls &&
                _difference(
                    Object.values(this.previousUrls),
                    Object.values(urls)
                )
            ) {
                this.previousUrls = urls;
                this.redirectTarget = urls.base + urls.redirect;
                this.redirectButton?.setAttribute('href', this.redirectTarget);
            }
        });
    }
}
