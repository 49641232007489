import { HttpVerb } from '../config';

export interface RequestDataTypeMap extends Record<HttpVerb, any> {
    [HttpVerb.POST_JSON]: Record<string, any>;
    [HttpVerb.GET]: Record<string, any>;
    [HttpVerb.POST_FORM]: FormData;
}

export class Route<
    RouteReturnType,
    Data extends RequestDataTypeMap[HttpVerb] = null,
    PathArgs extends Array<any> = []
> {
    public static make<
        R,
        D extends Record<string, any> | FormData = null,
        P extends Array<any> = []
    >(
        path: string | ((...args: P) => string),
        method?: HttpVerb
    ): Route<R, D, P> {
        return new Route<R, D, P>(path, method);
    }

    public readonly path: (...args: PathArgs) => string;

    protected constructor(
        _path: string | ((...args: any[]) => string),
        public readonly method: HttpVerb = HttpVerb.GET
    ) {
        if (typeof _path === 'string') {
            this.path = () => _path;
        } else {
            this.path = _path;
        }
    }

    /*
     * Soooo, yes, we're tricking TypeScript. It complains that the generics
     * "RouteReturnType" and "Data" are unused, even though they're used very
     * effectively for type hinting.
     */
    private dummyRequestReturnType(): RouteReturnType {
        this.dummyRequestDataType();
        return;
    }

    private dummyRequestDataType(): Data {
        this.dummyRequestReturnType();
        return;
    }
}
