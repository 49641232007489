import type { GameApi, ApiState, Team } from 'urban-challenger-sdk';

import { EnhancedElement } from '@gebruederheitz/energize';
import { debug } from '@gebruederheitz/debuggable';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/de';
import 'dayjs/locale/en';

dayjs.extend(duration);
dayjs.extend(relativeTime);

export class TeamSummaries {
    private readonly debug = debug.spawn(this);
    private rows: Map<string, TeamSummary> = new Map();
    private lastTotalScore: number = 0;

    constructor(protected sdk: GameApi) {
        this.init().then();
    }

    public destroy(): boolean {
        this.debug.log('Destroying team summaries instance!');
        this.rows.forEach((row) => {
            row.destroy();
        });
        this.rows = new Map();

        return true;
    }

    private async init(): Promise<void> {
        const state = await this.sdk.getState();
        this.debug.log('api state', state);
        state?.teams?.forEach((team) => {
            this.rows.set(team.code, this.createRow(team, state));
        });

        this.sdk.subscribe((state: ApiState) => {
            if (state.game.moderated && !state.game.resultsDeclared) {
                // all other teams are currently hidden / blurred, because
                // the results are not yet declared in a moderated game.
                // Simply heave the player's own team to the top of the pile.
                const playersTeamCode = state.team.code;
                const playersTeam = this.rows.get(playersTeamCode);
                if (
                    playersTeam.getElement() !==
                    playersTeam.parent().firstElementChild
                ) {
                    playersTeam
                        .parent()
                        .insertBefore(
                            playersTeam.getElement(),
                            playersTeam.parent().firstElementChild
                        );
                }
            } else {
                if (!state.teams) {
                    return;
                }

                // This game is either not moderated, or the results have
                // already been declared. We sort the teams by their respective
                // scores.
                // First we check whether the scores have changed, otherwise
                // we needn't bother with the DOM transformations.
                const totalScore = state.teams
                    .map((t) => t.score)
                    .reduce((prev, current) => prev + current, 0);
                if (totalScore !== this.lastTotalScore) {
                    this.lastTotalScore = totalScore;
                    this.sortTeamsInDom(state);
                }
            }
        });
    }

    private createRow(team: Team, state: ApiState): TeamSummary {
        return TeamSummary.fromTemplate(this.sdk, team, state);
    }

    private sortTeamsInDom(state: ApiState): void {
        const teams = [...state.teams];
        const sortedTeamCodes = teams
            .sort((a, b) => {
                if (a.score > b.score) {
                    return 1;
                } else if (b.score > a.score) {
                    return -1;
                }
                return 0;
            })
            .map((t) => t.code);

        sortedTeamCodes.forEach((tCode, index) => {
            if (index < sortedTeamCodes.length - 1) {
                const teamRow = this.rows.get(tCode).getElement();
                const nextRow = this.rows.get(sortedTeamCodes[index + 1]);
                teamRow.parentElement.insertBefore(
                    nextRow.getElement(),
                    teamRow
                );
            }
        });
    }
}

export class TeamSummary extends EnhancedElement<HTMLTableElement, GameApi> {
    protected static template: EnhancedElement<HTMLElement> | null = null;

    protected teamNameDisplay: EnhancedElement<any> | null;
    protected playTimeDisplay: EnhancedElement<any> | null;
    protected teamScoreDisplays: EnhancedElement<any>[] | null;
    protected taskCountDisplay: EnhancedElement<any> | null;
    protected teamFinishedDisplay: EnhancedElement<any> | null;
    protected playerCountDisplay: EnhancedElement<any> | null;
    protected teamPhotoDisplay: EnhancedElement<any> | null;

    public static fromTemplate(
        sdk: GameApi,
        team: Team,
        state: ApiState
    ): TeamSummary {
        if (!TeamSummary.template) {
            TeamSummary.template = EnhancedElement.fromSelector<HTMLElement>(
                '[data-game-component="teams-summary-template"]'
            );
        }

        const templateElement = TeamSummary.template.getElement();
        return TeamSummary.template
            .cloneInto(TeamSummary, team, state)
            .connect(sdk)
            .insertAfter(templateElement);
    }

    public constructor(e, protected team: Team, protected state: ApiState) {
        super(e);
        this.hide();
    }

    public override appendTo(element): this {
        super.appendTo(element);
        this.onAddToDom();

        return this;
    }

    public override insertAfter(element: Element): this {
        super.insertAfter(element);
        this.onAddToDom();

        return this;
    }

    protected onAddToDom(): void {
        this.setAttribute('data-game-component', 'teams-summary');
        this.parseComponents();
        this.updateComponents(this.team);
    }

    protected onStateUpdate = (state: ApiState): void => {
        const teams = state.teams;
        this.state = state;
        const thisTeam =
            (teams && teams.find((t) => t.code === this.team.code)) || null;
        if (thisTeam) {
            this.updateComponents(thisTeam);
        }
    };

    protected parseComponents(): void {
        this.teamNameDisplay = this.findAndWrap(
            '[data-game-component="summary-team-name"]'
        );
        this.playTimeDisplay = this.findAndWrap(
            '[data-game-component="summary-play-time"]'
        );
        this.teamScoreDisplays = this.findAndWrapAll(
            '[data-game-component="summary-team-score"]'
        );
        this.taskCountDisplay = this.findAndWrap(
            '[data-game-component="summary-task-count"]'
        );
        this.teamFinishedDisplay = this.findAndWrap(
            '[data-game-component="summary-team-finished"]'
        );
        this.playerCountDisplay = this.findAndWrap(
            '[data-game-component="summary-team-players"]'
        );
        this.teamPhotoDisplay = this.findAndWrap(
            '[data-game-component="summary-team-photo"]'
        );
    }

    protected updateComponents(team: Team): void {
        if (!this.state.team) {
            return;
        }

        const isUsersTeam = this.state.team.code === team.code;

        if (
            !isUsersTeam &&
            this.state.game.moderated &&
            !this.state.game.resultsDeclared
        ) {
            this.hide();
        } else {
            this.show();
        }

        const teamFinished = dayjs(team.endtime) < dayjs();

        this.teamNameDisplay?.content(team.name || team.code);
        this.playTimeDisplay?.content(
            team.starttime
                ? dayjs
                      .duration(
                          dayjs(team.starttime).diff(
                              teamFinished ? team.endtime : dayjs()
                          )
                      )
                      .humanize()
                : 'not started'
        );
        if (this.teamScoreDisplays?.length) {
            this.teamScoreDisplays.forEach((tsd) => {
                tsd.content(team.score.toString(10));
            });
        }
        this.taskCountDisplay?.content(
            Object.keys(team.tasks).length.toString(10)
        );
        this.teamFinishedDisplay?.content(teamFinished ? '✔' : '✖');
        this.playerCountDisplay?.content(team.members.length.toString(10));

        this.teamPhotoDisplay?.setAttribute('src', team.photo || '');

        this.setAttribute(
            'data-team-finished',
            teamFinished ? 'true' : 'false'
        );

        this.setAttribute('data-my-team', isUsersTeam ? 'true' : 'false');
    }
}
