import type { GameApi } from 'urban-challenger-sdk';
import { EnhancedElement } from '@gebruederheitz/energize';

import { confirm } from '../util/confirm';

export class StartDemoGame extends EnhancedElement<HTMLElement> {
    constructor(button: HTMLElement, protected sdk: GameApi) {
        super(button);
        this.on('click', this.onClick);
    }

    onClick = async (e) => {
        e.preventDefault();

        if (await confirm('confirmation.demo.start', 'question')) {
            await this.sdk.startDemo();
            const { urls } = await this.sdk.getState();

            window.location.assign(urls.base + urls.redirect);
        }
    };
}
