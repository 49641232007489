import { Challenge } from './types';

export const CategoriesOrder = [
    'explorer',
    'artist',
    'time-traveler',
    'connector',
    'nature-lover',
    'foodie',
];

export class CategorySorter {
    /**
     * Sorts the challenges by category in a pre-defined order of categories.
     */
    public static sort(slides: Challenge[]): Challenge[] {
        return slides.sort((a, b) => {
            if (
                CategoriesOrder.indexOf(a.categorySlug) >
                CategoriesOrder.indexOf(b.categorySlug)
            ) {
                return 1;
            } else if (
                CategoriesOrder.indexOf(a.categorySlug) <
                CategoriesOrder.indexOf(b.categorySlug)
            ) {
                return -1;
            }

            return 0;
        });
    }
}
