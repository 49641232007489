import type { Challenge, SlidesFilteredEvent } from './types';

import { debug } from '@gebruederheitz/debuggable';
import { EnhancedElement } from '@gebruederheitz/energize';

import { ChallengeSlideshow } from './index';
import { CategoriesOrder } from './category-sorter';

export class CategoryNavigation extends EnhancedElement<HTMLElement> {
    protected readonly debug = debug.spawn(this);
    protected readonly tabs: Map<string, EnhancedElement<HTMLElement>>;

    protected lastCategorySlug: string | null = null;
    protected activeTab: EnhancedElement<HTMLElement>;

    constructor(
        element: HTMLElement,
        protected readonly slideshow: ChallengeSlideshow
    ) {
        super(element);
        this.tabs = new Map<string, EnhancedElement<HTMLElement>>(
            CategoriesOrder.map((e) => {
                return [e, this.findAndWrap(`[data-category-tab="${e}"]`)];
            })
        );

        this.activeTab = this.tabs.get(CategoriesOrder[0]);
        this.activeTab?.addClass('active');

        for (let [categorySlug, tab] of this.tabs.entries()) {
            tab.on('click', () => {
                this.slideshow.scrollToCategory(categorySlug);
            });
        }

        slideshow.on('slide-change', this.onSlideChange);
        slideshow.on('slides-filtered', this.onSlidesFiltered);

        if (slideshow.isInitialized()) {
            this.onInit(slideshow.getSlides());
        } else {
            slideshow.on('init', this.onInit);
        }
    }

    protected onSlidesFiltered = ({
        slides,
        currentSlide,
    }: SlidesFilteredEvent) => {
        this.updateDisabledStatus(slides);
        this.updateActiveTab(currentSlide);
    };

    protected onInit = (slides: Challenge[]) => {
        this.updateDisabledStatus(slides);
    };

    protected onSlideChange = (slide: Challenge) => {
        this.debug.log('slide change event received', slide);
        this.updateActiveTab(slide);
    };

    protected updateActiveTab(slide: Challenge) {
        if (slide && slide.categorySlug !== this.lastCategorySlug) {
            this.lastCategorySlug = slide.categorySlug;

            if (this.activeTab) {
                this.activeTab.removeClass('active');
            }

            const tab = this.tabs.get(slide.categorySlug);
            tab.addClass('active');

            this.activeTab = tab;
        }
    }

    protected updateDisabledStatus(slides: Challenge[]) {
        CategoriesOrder.forEach((cat) => {
            if (slides.some((e) => e.categorySlug === cat)) {
                this.tabs.get(cat).removeClass('disabled');
            } else {
                this.tabs.get(cat).addClass('disabled');
            }
        });
    }
}
