import type { Emitter } from 'mitt';
import type { QrReader, QrReaderEvents } from './qr-reader';

import mitt from 'mitt';

declare global {
    class NativelyScanner {
        showScanner(callback: (result) => void);
    }
}

export class NativelyQrReader implements QrReader {
    protected readonly api: NativelyScanner = new NativelyScanner();
    protected readonly events: Emitter<QrReaderEvents> = mitt<QrReaderEvents>();

    public show(): void {
        this.api.showScanner(this.onUpdate);
    }

    public onScanResult(callback: (result: string) => void) {
        this.events.on('scan-result', callback);
    }

    public retry(): void {
        this.show();
    }

    public stop(): void {
        // no need to do anything, it has stopped when the results came in
    }

    protected onUpdate = ({ result }) => {
        this.events.emit('scan-result', result);
    };
}
