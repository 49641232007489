import type { ApiState } from 'urban-challenger-sdk';
import type { LobbyType } from './interface';

import { debug } from '@gebruederheitz/debuggable';
import { GameApi, UserStatus } from 'urban-challenger-sdk';

import { OwnerLobby } from './owner';
import { BaseLobby } from './base';
import { TeamLobby } from './team';
import { RedirectLobby } from './redirect';

/**
 * This widget will show/hide the appropriate lobby container based on the
 * user's status.
 */
export class Lobby {
    private readonly debug = debug.spawn(this);

    protected teamLobby: TeamLobby | DummyLobby;
    protected ownerLobby: LobbyType;
    protected joinLobby: LobbyType;
    protected redirectLobby: LobbyType;

    protected previousStatus: UserStatus;

    protected activeLobby: LobbyType;

    constructor(
        protected readonly rootElement: HTMLElement,
        protected readonly sdk: GameApi
    ) {
        const teamLobbyElement: HTMLElement | null =
            this.rootElement.querySelector('[data-game-lobby="team"]');
        this.teamLobby = teamLobbyElement
            ? new TeamLobby(teamLobbyElement, sdk)
            : new DummyLobby();

        const ownerLobbyElement: HTMLElement | null =
            this.rootElement.querySelector('[data-game-lobby="owner"]');
        this.ownerLobby = ownerLobbyElement
            ? // ? new BaseLobby(ownerLobbyElement, sdk)
              new OwnerLobby(ownerLobbyElement, sdk)
            : new DummyLobby();

        const joinLobbyElement: HTMLElement | null =
            this.rootElement.querySelector('[data-game-lobby="join"]');
        this.joinLobby = joinLobbyElement
            ? new BaseLobby(joinLobbyElement, sdk)
            : new DummyLobby();

        const redirectLobbyElement: HTMLElement | null =
            this.rootElement.querySelector('[data-game-lobby="redirect"]');
        this.redirectLobby = redirectLobbyElement
            ? new RedirectLobby(redirectLobbyElement, sdk)
            : new DummyLobby();

        this.activeLobby = this.joinLobby;

        this.sdk.subscribe(this.onStatus);
    }

    protected onStatus = (state: ApiState): void => {
        const userStatus = state.status;
        if (this.previousStatus !== userStatus) {
            this.debug.log('user status changed, switching lobbies');
            this.previousStatus = userStatus;
            this.debug.log('hiding active lobby', this.activeLobby);
            this.activeLobby.hide();
            const newLobby = this.setLobbyActiveByStatus(userStatus);
            this.debug.log('new lobby is', newLobby);
            newLobby.show();
            this.activeLobby = newLobby;
        }
    };

    protected setLobbyActiveByStatus(userStatus: UserStatus): LobbyType {
        let lobby: LobbyType;

        switch (userStatus) {
            case UserStatus.GAME_ENDED:
                // @TODO: show game summary
                if (false) {
                    break;
                }
            // fallthrough (optional)
            case UserStatus.UNIDENTIFIED:
            case UserStatus.LOBBY:
                lobby = this.joinLobby;
                break;
            case UserStatus.OWNER_LOBBY:
                this.debug.log('its the owner lobby');
                lobby = this.ownerLobby;
                break;
            case UserStatus.TEAM_LOBBY:
            case UserStatus.TEAM_LOBBY_LEADER:
                lobby = this.teamLobby;
                break;
            case UserStatus.PLAYING:
                lobby = this.redirectLobby;
                break;
            case UserStatus.INITIAL:
            default:
                return this.activeLobby;
        }

        this.debug.log('showing lobby');
        lobby.show();

        return lobby;
    }
}

class DummyLobby implements LobbyType {
    public show(): void {}
    public hide(): void {}
}
