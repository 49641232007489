import { GameApi, toasts } from 'urban-challenger-sdk';
import { debug } from '@gebruederheitz/debuggable';

import { alert, confirmPrintf } from '../util/confirm';
import { checkUrlForCode } from '../util/url-parameters';
import { AuthenticatedHttpConnectorEvents } from 'urban-challenger-sdk/src';

export class JoinTeam {
    protected static CODE_INPUT_NAME = 'join-team-code';
    private debug = debug.spawn(this);

    constructor(
        protected readonly form: HTMLFormElement,
        protected readonly sdk: GameApi
    ) {
        this.init().then();
    }

    protected async init() {
        await checkUrlForCode(
            'uc-team',
            this.onValidCodeFound,
            this.checkCodeValidity
        );

        this.form.addEventListener('submit', this.onSubmit);
    }

    protected checkCodeValidity = async (code: string): Promise<boolean> => {
        const { team } = await this.sdk.getState();

        // Stop processing if the user is already part of the team
        return !team || team.code !== code;
    };

    protected onValidCodeFound = async (code: string): Promise<boolean> => {
        if (
            await confirmPrintf('confirmation.join.team', 'question', {
                '{{code}}': code,
            })
        ) {
            return this.tryJoin(code);
        }

        return false;
    };

    protected async tryJoin(code: string): Promise<boolean> {
        this.debug.log('trying to join', code);

        const onError = async (errorCode: string) => {
            this.debug.log('error callback with', errorCode);

            if (errorCode === 'owns-game') {
                if (
                    await confirmPrintf(
                        'confirmation.join.team.and.archive',
                        'warning',
                        {
                            '{{code}}': code,
                        }
                    )
                ) {
                    console.log('archive game and move on');
                    const archived = await this.sdk.endAndArchiveGame();
                    if (archived) {
                        await this.sdk.joinTeam(code);
                    } else {
                        alert('alert.generic.error.retry', 'error');
                    }
                }
            }

            // we only listen to this event once
            this.sdk.off(AuthenticatedHttpConnectorEvents.CODED_ERROR, onError);
        };

        this.debug.log('attaching listener');
        this.sdk.on(AuthenticatedHttpConnectorEvents.CODED_ERROR, onError);
        const r = await this.sdk.joinTeam(code);

        this.debug.log('results are back, returning', r);
        return r !== null;
    }

    protected onSubmit = async (e: SubmitEvent): Promise<void> => {
        // Never actually submit the form anywhere, we handle submissions
        // asynchronously via the API.
        e.preventDefault();

        const formData = new FormData(this.form);
        if (!formData.has(JoinTeam.CODE_INPUT_NAME)) {
            console.error('No claim game code input found!');
            toasts.make(
                'An unknown error occurred. Please contact a site administrator.',
                'error'
            );
            return;
        }

        const code: string | null = <string | null>(
            formData.get(JoinTeam.CODE_INPUT_NAME)
        );

        if (!code) {
            toasts.make(
                'You need to enter a code in order to join a team.',
                'warn'
            );
            return;
        }

        if (code.length !== 6) {
            toasts.make('Invalid code!', 'warn');
        }

        await this.tryJoin(code);
    };
}
