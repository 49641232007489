import type { GameApi } from 'urban-challenger-sdk';
import { ApiVersion } from 'urban-challenger-sdk';
import { confirm } from '../util/confirm';

export class ChangeTeamName {
    constructor(
        protected readonly formElement: HTMLFormElement,
        protected readonly sdk: GameApi
    ) {
        formElement.addEventListener('submit', this.onSubmit);
    }

    onSubmit = async (e: SubmitEvent) => {
        e.preventDefault();

        const formData = new FormData(this.formElement);
        const teamName = <string>formData.get('team-name');

        if (!teamName) {
            return;
        }

        await this.sdk.updateTeamName(teamName);
        this.sdk.toasts.make('Team name updated.', 'success');
        this.formElement.reset();
    };
}

export class StartGame {
    constructor(
        protected readonly element: HTMLElement,
        protected readonly sdk: GameApi
    ) {
        element.addEventListener('click', this.onClick);
    }

    onClick = async (e) => {
        e.preventDefault();

        const {
            team: { name = null },
        } = await this.sdk.getState();

        if (!(await confirm('confirmation.team.start.generic', 'question'))) {
            return;
        }

        if (!name) {
            if (!(await confirm('confirmation.team.start.noname'))) {
                return;
            }
        }

        await this.sdk.startGame();
    };
}

/**
 * @deprecated Use separate "start game" and "change team name" components
 */
export class LegacyStartGame {
    constructor(
        protected readonly element: HTMLFormElement,
        protected readonly sdk: GameApi
    ) {
        element.addEventListener('submit', this.onSubmit);
    }

    onSubmit = async (e: SubmitEvent) => {
        e.preventDefault();

        const formData = new FormData(this.element);
        const teamName = <string>formData.get('team-name');

        if (!(await confirm('confirmation.team.start.generic', 'question'))) {
            return;
        }

        if (!teamName) {
            if (!(await confirm('confirmation.team.start.noname'))) {
                return;
            }
        }

        let result;

        if (this.sdk.version.isAtLeast(ApiVersion.SplitNameAndStart)) {
            const teamNameResult = await this.sdk.updateTeamName(teamName);
            if (teamNameResult) {
                result = await this.sdk.startGame();
            }
        } else {
            result = await this.sdk._deprecated_startGame(teamName);
        }

        if (
            result &&
            this.element instanceof HTMLAnchorElement &&
            this.element.href
        ) {
            window.location.assign(this.element.href);
        }
    };
}
