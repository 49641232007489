import type { ApiState, GameApi } from 'urban-challenger-sdk';

import { UserStatus } from 'urban-challenger-sdk';
import { EnhancedElement } from '@gebruederheitz/energize';

import { confirm } from '../util/confirm';

export class StopGame extends EnhancedElement<HTMLElement, GameApi> {
    protected previousStatus: UserStatus = UserStatus.INITIAL;

    constructor(e) {
        super(e);
        this.on('click', this.onClick.bind(this));
    }

    protected override onStateUpdate({ status, team, userId }: ApiState) {
        if (status !== this.previousStatus) {
            this.previousStatus = status;

            if (status === UserStatus.PLAYING && userId === team.leader) {
                this.show();
            } else {
                this.hide();
            }
        }
    }

    protected async onClick(e: MouseEvent): Promise<void> {
        e.preventDefault();

        const { status, team } = await this.store.getState();

        if (!team || status !== UserStatus.PLAYING) {
            return;
        }

        if (await confirm('confirmation.team.leader.finish')) {
            await this.store.stopGame();
        }
    }
}
