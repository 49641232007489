import dict from './dict.js';

const DEFAULT_LANGUAGE = 'en';
let locale = null;

export function localize(t: string): string {
    if (!locale) {
        locale = document.body.parentElement.lang || DEFAULT_LANGUAGE;
    }
    if (dict[t]) {
        return dict[t][locale] || t;
    }

    return t;
}

export function localizeOrNull(t: string): string | null {
    if (!locale) {
        locale = document.body.parentElement.lang || DEFAULT_LANGUAGE;
    }
    if (dict[t]) {
        return dict[t][locale] || t;
    }

    return null;
}
