import type { RequestDataTypeMap } from './util/route';

import Alert from 'sweetalert2/src/sweetalert2.js';
import { debug } from '@gebruederheitz/debuggable';
import mitt, { Emitter, EventType } from 'mitt';

import { Route } from './util/route';
import { toasts } from './util/toast';
import { HttpVerb, URLS } from './config';
import { localizeOrNull } from './util/i18n/localize';

class MaintenanceModeException extends Error {}

export enum AuthenticatedHttpConnectorEvents {
    CODED_ERROR = 'coded-error',
}

interface AuthenticatedHttpConnectorEventMap
    extends Record<EventType, unknown> {
    [AuthenticatedHttpConnectorEvents.CODED_ERROR]: string;
}

export class AuthenticatedHttpConnector {
    protected token: Promise<string | null>;
    protected maintenanceMode: boolean = false;
    protected maintenanceAlert: Alert | null = null;
    protected eventProxy: Emitter<AuthenticatedHttpConnectorEventMap> = mitt();

    constructor(protected url: string = URLS.LOCAL) {}

    public getBaseUrl(): string {
        return this.url;
    }

    public get on() {
        return this.eventProxy.on;
    }

    public get off() {
        return this.eventProxy.off;
    }

    protected requestRoute<
        V extends HttpVerb,
        T,
        D extends RequestDataTypeMap[V],
        P extends Array<any>
    >(
        route: Route<T, D, P>,
        data: D = null,
        ...pathArgs: P
    ): Promise<T | null> {
        const path = route.path(...pathArgs);
        let method;

        switch (route.method) {
            case HttpVerb.POST_JSON:
                method = this.post.bind(this);
                break;
            case HttpVerb.POST_FORM:
                method = this.postForm.bind(this);
                break;
            case HttpVerb.PATCH:
                method = this.patch.bind(this);
                break;
            case HttpVerb.DELETE:
                method = this.delete.bind(this);
                break;
            case HttpVerb.PUT:
                method = this.put.bind(this);
                break;
            case HttpVerb.GET:
            default:
                method = this.get.bind(this);
        }

        return this.withRecursiveMaintenanceModeHandler<T>(method, path, data);
    }

    /**
     * Perform a standard GET request with token authentication.
     */
    protected async get<R extends unknown>(
        path: string,
        params?: Record<string, any>
    ): Promise<R | null> {
        let query = '';
        if (params) {
            query = new URLSearchParams(params).toString();
            query = '?' + query;
        }

        return this.makeRequest(path + query);
    }

    /**
     * Perform an authenticated POST request to the API server.
     */
    protected async post<R extends unknown>(
        path: string,
        data: Record<string, any> = {}
    ): Promise<R | null> {
        return this.makeRequest(path, 'POST', {
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(data),
        });
    }

    /**
     * Perform an authenticated DELETE request to the API server.
     */
    protected async delete<R extends unknown>(
        path: string,
        data: Record<string, any> = {}
    ): Promise<R | null> {
        return this.makeRequest(path, 'DELETE', {
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(data),
        });
    }

    /**
     * Perform an authenticated PATCH request to the API server.
     */
    protected async patch<R extends unknown>(
        path: string,
        data: Record<string, any> = {}
    ): Promise<R | null> {
        return this.makeRequest(path, 'PATCH', {
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(data),
        });
    }

    /**
     * Perform an authenticated PUT request to the API server.
     */
    protected async put<R extends unknown>(
        path: string,
        data: Record<string, any> = {}
    ): Promise<R | null> {
        return this.makeRequest(path, 'PUT', {
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(data),
        });
    }

    /**
     * Perform an authenticated POST request to the API server using form-data.
     */
    protected async postForm<R extends unknown>(
        path: string,
        data: FormData
    ): Promise<R | null> {
        return this.makeRequest(path, 'POST', {
            headers: {
                // no content-type, so the browser can set multipart/form-data _with a form boundary_!
            },
            body: data,
        });
    }

    /**
     * Called when the endpoint returns a 401 response with a code of
     * 'token-expired'.
     */
    protected async onTokenExpired(): Promise<void> {}

    private async makeRequest<R extends unknown>(
        path,
        method = 'GET',
        {
            headers = {},
            body = null,
        }: { headers?: Record<string, string>; body?: string | FormData } = {}
    ): Promise<R | null> {
        try {
            const token = await this.token;
            const options: RequestInit = {
                method,
                headers: {
                    authorization: `Bearer ${token}`,
                    accept: 'application/json',
                    ...headers,
                },
            };

            if (body !== null) {
                options.body = body;
            }

            const r = await fetch(`${this.url}/api/${path}`, options);

            if (r.status === 503) {
                throw new MaintenanceModeException();
            }

            if (r.status === 404) {
                return null;
            }

            const content = await r.json();

            if (r.status === 401 && content?.code) {
                if (content.code === 'token-expired') {
                    await this.onTokenExpired();
                    // We leave it to implementing classes to throw an
                    // exception or show a toast if they so desire.
                    return null;
                }
            }

            if (r.status > 299) {
                if (content.code) {
                    this.eventProxy.emit(
                        AuthenticatedHttpConnectorEvents.CODED_ERROR,
                        content.code
                    );
                } else {
                    toasts.make(content.message || 'Unknown error', 'error');
                }

                return null;
            }

            return content;
        } catch (e) {
            if (e instanceof MaintenanceModeException) {
                // rethrow this exception, as it needs to end up in the MM handler
                throw e;
            }

            console.warn('Failed to establish API connection', e);
            return null;
        }
    }

    private async withRecursiveMaintenanceModeHandler<T>(
        method,
        path,
        data
    ): Promise<T | null> {
        let caught = false;

        try {
            return await method(path, data);
        } catch (e) {
            if (e instanceof MaintenanceModeException) {
                caught = true;
                if (!(this.maintenanceMode && this.maintenanceAlert)) {
                    await this.enableMaintenanceMode();
                }

                await debug.timeout(8000);
                return this.withRecursiveMaintenanceModeHandler(
                    method,
                    path,
                    data
                );
            } else {
                // rethrow any non-MM exceptions, we shouldn't even be receiving any
                throw e;
            }
        } finally {
            if (this.maintenanceMode && !caught) {
                // MM is active, but the last request went through: reset
                await this.disableMaintenanceMode();
            }
        }
    }

    private async enableMaintenanceMode() {
        this.maintenanceMode = true;
        this.maintenanceAlert = Alert.fire({
            title: localizeOrNull('maintenance.title'),
            text: localizeOrNull('maintenance.body'),
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            icon: false,
            imageUrl: this.url + '/img/maintenance.jpg',
        });
    }

    private async disableMaintenanceMode() {
        this.maintenanceAlert?.close();
        this.maintenanceMode = false;
    }
}
