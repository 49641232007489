import type Swiper from 'swiper';

import hexRgb from 'hex-rgb';
import { EnhancedElement } from '@gebruederheitz/energize';

import { Challenge } from './types';

/**
 * Updates the CSS Custom Property "--background" every time the category of
 * the currently displayed challenge slide changes.
 */
export class BackgroundUpdater {
    protected backgroundElement: EnhancedElement<HTMLElement>;
    protected previousCategory: string = null;

    constructor(protected readonly backgroundContainer: HTMLElement) {
        this.backgroundElement = EnhancedElement.fromElement(
            backgroundContainer
        ).findAndWrap('[data-game-challenge="background"]');
    }

    public update(swiper: Swiper, slides: Challenge[]) {
        const slideData = swiper?.realIndex
            ? slides[swiper.realIndex]
            : slides[0];
        const category = slideData.categorySlug;

        if (category !== this.previousCategory) {
            this.previousCategory = category;
            const newBackground = this.backgroundElement.clone();
            newBackground
                .getElement()
                .style.setProperty('--background', `var(--${category})`);
            newBackground.on('animationend', () => {
                this.backgroundElement.destroy();
                this.backgroundElement = newBackground;
            });
            newBackground.appendTo(this.backgroundContainer);

            const color = window
                .getComputedStyle(newBackground.getElement())
                .getPropertyValue('--background');

            const rgbArray = hexRgb(color, { format: 'array' });
            // get rid of alpha value
            rgbArray.pop();
            const rgb = rgbArray.join(' ');
            document.body.style.setProperty('--gh-modal-backdrop-color', rgb);
        }
    }
}
