import { Alert } from 'urban-challenger-sdk';
import { localize, localizeOrNull } from './i18n/localize';

type SwalIcon = 'warning' | 'success' | 'error' | 'info' | 'question';

export async function confirm(
    i18nNamespace,
    icon: SwalIcon = 'warning'
): Promise<boolean> {
    const { value: confirmed } = await Alert.fire({
        title: localizeOrNull(i18nNamespace + '.title'),
        text: localizeOrNull(i18nNamespace + '.body'),
        confirmButtonText:
            localizeOrNull(i18nNamespace + '.confirm') ||
            localize('generic.confirm'),
        cancelButtonText:
            localizeOrNull(i18nNamespace + '.cancel') ||
            localize('generic.cancel'),
        icon,
        showCancelButton: true,
    });

    return confirmed;
}

/**
 * SweetAlert2 confirmation prompt that includes a "type-action" input field
 * challenge to really make certain a highly destructive action is intended.
 *
 * The i18nNamespace entries should contain (in addition to the fields used by
 * a regular confirmation) "input-label" and "input-expected". You may
 * additionally provide 'input-validation-error' for a custom error message.
 */
export async function confirmWithSafety(
    i18nNamespace,
    icon: SwalIcon = 'error'
): Promise<boolean> {
    const { value: confirmed } = await Alert.fire({
        title: localizeOrNull(i18nNamespace + '.title'),
        text: localizeOrNull(i18nNamespace + '.body'),
        input: 'text',
        inputLabel: localizeOrNull(i18nNamespace + '.input-label'),
        inputValue: '',
        inputValidator: (v) => {
            if (!v || v !== localizeOrNull(i18nNamespace + '.input-expected')) {
                return (
                    localizeOrNull(i18nNamespace + '.input-validation-error') ||
                    'Invalid input'
                );
            }
        },
        inputAttributes: {
            autocapitalize: 'off',
            autocorrect: 'off',
        },
        confirmButtonText:
            localizeOrNull(i18nNamespace + '.confirm') ||
            localize('generic.confirm'),
        cancelButtonText:
            localizeOrNull(i18nNamespace + '.cancel') ||
            localize('generic.cancel'),
        icon,
        showCancelButton: true,
    });

    return confirmed;
}

export async function confirmPrintf(
    i18nNamespace,
    icon: SwalIcon = 'warning',
    map: Record<string, string>
): Promise<boolean> {
    const re = new RegExp(Object.keys(map).join('|'), 'g');
    const replacements = (v) => {
        return map[v] || v;
    };

    let title = localizeOrNull(i18nNamespace + '.title');
    if (title) {
        title = title.replaceAll(re, replacements);
    }

    let body = localizeOrNull(i18nNamespace + '.body');
    if (body) {
        body = body.replaceAll(re, replacements);
    }

    const { value: confirmed } = await Alert.fire({
        title,
        text: body,
        confirmButtonText:
            localizeOrNull(i18nNamespace + '.confirm') ||
            localize('generic.confirm'),
        cancelButtonText:
            localizeOrNull(i18nNamespace + '.cancel') ||
            localize('generic.cancel'),
        icon,
        showCancelButton: true,
    });

    return confirmed;
}

export function alert(i18nNamespace, icon: SwalIcon = 'warning'): void {
    Alert.fire({
        title: localizeOrNull(i18nNamespace + '.title'),
        text: localizeOrNull(i18nNamespace + '.body'),
        confirmButtonText:
            localizeOrNull(i18nNamespace + '.confirm') ||
            localize('generic.confirm'),
        icon,
    });
}

/**
 * @deprecated Try not to use this in production!
 */
export function alertLiteral(
    body: string,
    title: string = null,
    icon: SwalIcon = 'warning'
): void {
    Alert.fire({
        title: title,
        text: body,
        confirmButtonText: localize('generic.confirm'),
        icon,
    });
}
