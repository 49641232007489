import { EventType } from 'mitt';

export interface QrReader {
    show(): void;
    onScanResult(callback: (result: string) => void);
    retry(): void;
    stop(): void;
}

export interface QrReaderEvents extends Record<EventType, unknown> {
    [QrReaderEvent.SCAN_RESULT]: string;
}

export enum QrReaderEvent {
    SCAN_RESULT = 'scan-result',
}
