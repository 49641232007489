import type { ApiState, GameApi } from 'urban-challenger-sdk';

import { EnhancedElement } from '@gebruederheitz/energize';

export class TeamPhotoDisplay extends EnhancedElement<
    HTMLImageElement,
    GameApi
> {
    protected imageUrl: string;

    protected onStateUpdate(state: ApiState) {
        if (!state?.team?.photo) {
            this.hide();
            this.imageUrl = null;
            this.element.src = '';
            return;
        }

        if (state.team.photo !== this.imageUrl) {
            this.imageUrl = state.team.photo;
            this.element.src = this.imageUrl;
            this.show();
        }
    }
}
