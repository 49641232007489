import type { GameApi, Task, Writable } from 'urban-challenger-sdk';
import type { TaskWrapperElement } from '../global';

import { writable } from 'urban-challenger-sdk';
import { debug } from '@gebruederheitz/debuggable';
import { EnhancedElement } from '@gebruederheitz/energize';

import { SubmissionForm } from './submission-form';
import { ResubmitButton } from './resubmit-button';
import { TaskStatusDisplayComponent } from './task-status-display-component';
import { ReviewComment } from './review-comment';

export enum TaskState {
    OPEN,
    PENDING,
    APPROVED,
    REJECTED,
}

export type TaskStateStore = Writable<TaskState>;
export type TaskStore = Writable<Task | null>;

export class SubmissionAndStatus extends EnhancedElement<
    TaskWrapperElement,
    GameApi
> {
    private readonly debug = debug.spawn(this);
    private readonly resubmitActiveStore: Writable<boolean> = writable(false);
    private readonly taskStateStore: TaskStateStore = writable(TaskState.OPEN);
    private readonly taskStore: TaskStore = writable(null);
    private previousTaskState: TaskState = TaskState.OPEN;

    constructor(
        e,
        private readonly taskSlug: string,
        private readonly taskPoints: number | null = null
    ) {
        super(e);
    }

    public async init(isNative: boolean): Promise<void> {
        this.debug.log('init status component for task', this.taskSlug);

        let taskPoints: number;
        if (this.taskPoints === null) {
            taskPoints = this.element.dataset.ucTaskPoints
                ? parseInt(this.element.dataset.ucTaskPoints, 10)
                : 1;
        } else {
            taskPoints = this.taskPoints;
        }

        // Submission Forms
        this.findAndWrapAllInto(
            '[data-uc-task-component="form"]',
            SubmissionForm,
            this.taskStateStore,
            this.taskSlug,
            taskPoints,
            this.store,
            this.resubmitActiveStore,
            isNative
        );

        // Resubmit Buttons
        this.findAndWrapAllInto(
            '[data-uc-task-component="resubmit"]',
            ResubmitButton,
            this.taskStateStore,
            this.resubmitActiveStore
        );

        // "Pending" displays
        TaskStatusDisplayComponent.makeAll(
            this.element,
            'pending',
            TaskState.PENDING,
            this.taskStateStore
        );
        // "Rejected" displays
        TaskStatusDisplayComponent.makeAll(
            this.element,
            'rejected',
            TaskState.REJECTED,
            this.taskStateStore
        );
        // "Approved" displays
        TaskStatusDisplayComponent.makeAll(
            this.element,
            'completed',
            TaskState.APPROVED,
            this.taskStateStore
        );

        // Review Comments
        this.findAndWrapAllInto(
            '[data-uc-task-component="comment"]',
            ReviewComment,
            this.store,
            this.taskStore
        );
    }

    protected onStateUpdate(state) {
        const tasks = state.team?.tasks || null;

        if (tasks) {
            const rawTaskState = tasks[this.taskSlug]?.state || 'open';
            let taskState: TaskState;

            switch (rawTaskState) {
                case 'pending':
                    taskState = TaskState.PENDING;
                    break;
                case 'approved':
                    taskState = TaskState.APPROVED;
                    break;
                case 'rejected':
                    taskState = TaskState.REJECTED;
                    break;
                case 'open':
                default:
                    taskState = TaskState.OPEN;
                    break;
            }

            if (taskState !== this.previousTaskState) {
                this.previousTaskState = taskState;
                this.taskStateStore.update(() => taskState);
                this.element.dataset.ucTaskStatus = rawTaskState;
            }

            this.taskStore.update(() => tasks[this.taskSlug] || null);
        }
    }
}
