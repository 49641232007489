// import type { Html5QrcodeResult } from 'html5-qrcode';
import type { Emitter } from 'mitt';

import mitt from 'mitt';
import { Html5QrcodeScanner, Html5QrcodeScannerState } from 'html5-qrcode';
import { createElement } from 'urban-challenger-sdk/src/util/create-element';
import { debug } from '@gebruederheitz/debuggable';

import { QrReader, QrReaderEvent, QrReaderEvents } from './qr-reader';

export class WebQrReader implements QrReader {
    protected static elementId = 'qr-scan';

    protected readonly events: Emitter<QrReaderEvents> = mitt();
    protected scanner: Html5QrcodeScanner;
    protected modal: HTMLCustomModalElement | null;
    protected modalContent: HTMLElement = createElement({
        attributes: { id: WebQrReader.elementId },
    });
    protected modalReady: Promise<void>;
    protected debug = debug.spawn(this);

    constructor() {
        this.modalReady = this.createModal();
    }

    public onScanResult(callback: (result: string) => void) {
        this.events.on(QrReaderEvent.SCAN_RESULT, callback);
    }

    public retry(): void {
        if (this.scanner.getState() === Html5QrcodeScannerState.PAUSED) {
            this.scanner.resume();
        }
    }

    public show = async (): Promise<void> => {
        await this.modalReady;

        this.attachScanner();
        this.modal?.show();
    };

    public stop(): void {
        this.modal?.hide();
        this.reset().then();
    }

    protected async createModal(): Promise<void> {
        this.debug.log('creating modal');
        const Modal = await window.customElements.whenDefined('gh-modal');
        this.modal = new Modal() as HTMLCustomModalElement;
        this.modal.id = WebQrReader.elementId + '-modal';
        createElement({
            classNames: ['uc-scanner-modal', 'modal-content'],
            children: [
                createElement({ type: 'H3', content: 'Scan QR Code' }),
                this.modalContent,
            ],
            parent: this.modal,
        });

        this.modal.addEventListener(
            'visibilitychange' as keyof HTMLElementEventMap,
            () => {
                if (!this.modal.open) {
                    this.reset();
                }
            }
        );

        document.body.appendChild(this.modal);
    }

    protected attachScanner(): void {
        this.scanner = new Html5QrcodeScanner(
            WebQrReader.elementId,
            {
                fps: 2,
                qrbox: { width: 300, height: 300 },
            },
            false
        );

        // this.scanner.render(this.onScanSuccess, this.onScanFailure);
        this.scanner.render(this.onScanSuccess, () => null);
    }

    protected async reset(): Promise<void> {
        this.debug.log('resetting scanner');
        await this.scanner.clear();
    }

    protected onScanSuccess = async (
        decodedText: string
        // result: Html5QrcodeResult
    ) => {
        this.scanner.pause();
        this.events.emit(QrReaderEvent.SCAN_RESULT, decodedText);
    };

    // protected onScanFailure = (err) => {
    // protected onScanFailure = () => {
    // this means nothing, really – any captured frame that doesn't contain
    // a code will trigger this callback, making it pretty much useless
    // };
}
