/**
 * The endgame component to display a game summary.
 */
import type { ApiState, GameApi, Team } from 'urban-challenger-sdk';

import { UserStatus } from 'urban-challenger-sdk';
import { EnhancedElement } from '@gebruederheitz/energize';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/de';
import 'dayjs/locale/en';

import { TeamSummaries } from './team-summary';

dayjs.extend(duration);
dayjs.extend(relativeTime);

export class GameSummary extends EnhancedElement<HTMLElement> {
    protected previousUserStatus: UserStatus = UserStatus.INITIAL;

    protected teamNameDisplay: EnhancedElement<HTMLElement> | null;
    protected scoreDisplay: EnhancedElement<HTMLElement> | null;
    protected playTimeDisplay: EnhancedElement<HTMLElement> | null;
    protected taskCountDisplay: EnhancedElement<HTMLElement> | null;
    protected summaries: TeamSummaries | null = null;

    constructor(element: HTMLElement, protected sdk: GameApi) {
        super(element);

        this.hide();

        this.teamNameDisplay = EnhancedElement.fromSelector<HTMLElement>(
            '[data-game-component="team-name"]',
            this.element
        );
        this.scoreDisplay = EnhancedElement.fromSelector<HTMLElement>(
            '[data-game-component="score"]',
            this.element
        );
        this.playTimeDisplay = EnhancedElement.fromSelector<HTMLElement>(
            '[data-game-component="play-time"]',
            this.element
        );
        this.taskCountDisplay = EnhancedElement.fromSelector<HTMLElement>(
            '[data-game-component="task-count"]',
            this.element
        );

        this.sdk.subscribe(this.onStateUpdate);
    }

    protected onStateUpdate = ({ status, team }: ApiState): void => {
        if (status !== this.previousUserStatus) {
            this.previousUserStatus = status;

            if (status === UserStatus.GAME_ENDED) {
                this.updateComponents(team);
                if (!this.summaries) {
                    this.summaries = new TeamSummaries(this.sdk);
                }
                this.show();
            }

            if (!team || status !== UserStatus.GAME_ENDED) {
                if (this.summaries?.destroy()) {
                    this.summaries = null;
                }
                this.hide();
            }
        }
    };

    protected updateComponents(team: Team): void {
        this.scoreDisplay.content(team.score.toString(10));
        this.teamNameDisplay.content(team.name);
        this.playTimeDisplay.content(
            dayjs.duration(dayjs(team.starttime).diff(team.endtime)).humanize()
        );
        this.taskCountDisplay.content(
            Object.keys(team.tasks).length.toString(10)
        );
    }
}
