import type { GameApi } from 'urban-challenger-sdk';

import { createElement } from 'urban-challenger-sdk';
import { EnhancedElement } from '@gebruederheitz/energize';

import { TeamPhotoCropper } from './cropper';

export class TeamPhotoEdit extends EnhancedElement<HTMLElement> {
    protected readonly thumbsContainer: EnhancedElement<HTMLElement>;
    protected thumbs: EnhancedElement<HTMLLIElement | HTMLDivElement>[] = [];
    protected modal: HTMLCustomModalElement;
    protected thumbsElementType: 'DIV' | 'LI' = 'DIV';

    constructor(element: HTMLElement, protected readonly sdk: GameApi) {
        super(element);
        this.thumbsContainer = this.findAndWrap('[data-team-photo="thumbs"]');
        if (this.thumbsContainer.getElement().matches('ul')) {
            this.thumbsElementType = 'LI';
        }

        this.modal = createElement({
            type: 'gh-modal',
            children: [this.element],
            parent: document.body,
            attributes: {
                id: 'team-photo-select',
            },
        });

        const uploader = this.findAndWrap<HTMLInputElement>('uc-file-upload');
        uploader.on('change', async () => {
            const { team } = await this.sdk.getState();
            const file = uploader.getElement().files[0];
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                const dataUri = reader.result;
                new TeamPhotoCropper(this.sdk, team.code).init(
                    dataUri as string
                );
                this.modal.hide();
            });
            reader.readAsDataURL(file);
        });

        this.removeClass('hidden');
    }

    show(): this {
        this.refresh().then();
        this.modal.show();

        return this;
    }

    protected refresh = async () => {
        this.thumbs.forEach((thumb) => {
            thumb.destroy();
        });
        this.thumbs = [];

        const selection = await this.getSelection();
        const { team } = await this.sdk.getState();
        selection.forEach(({ imageUrl }) => {
            const thumb = EnhancedElement.fromDefinition<'li' | 'div'>({
                type: this.thumbsElementType,
                classNames: ['team-photo-thumb'],
                children: [
                    createElement({
                        type: 'IMG',
                        classNames: ['team-photo-thumb'],
                        attributes: {
                            src: imageUrl,
                        },
                    }),
                ],
                parent: this.thumbsContainer.getElement(),
            });
            thumb.on('click', () => {
                new TeamPhotoCropper(this.sdk, team.code).init(imageUrl);
                this.modal.hide();
            });
            this.thumbs.push(thumb);
        });
    };

    protected async getSelection() {
        const { team } = await this.sdk.getState();
        if (!team) return;

        return this.sdk.getTeamPhotoSelection(team.code);
    }
}
