import type { ApiState, GameApi } from 'urban-challenger-sdk';

import { toasts } from 'urban-challenger-sdk';
import { EnhancedElement } from '@gebruederheitz/energize';

export class CreateTeams extends EnhancedElement<HTMLFormElement> {
    // requires , a select or numeric input for setting the number of teams in
    // the game and a submit button
    // the input needs to have the following name:
    public TEAM_COUNT_INPUT_NAME: string = 'create-team-count';

    protected maxDevices: number | null = null;

    constructor(form: HTMLFormElement, protected readonly sdk: GameApi) {
        super(form);
        this.element.addEventListener('submit', this.onSubmit);
        this.sdk.subscribe(({ maxDevices }: ApiState) => {
            this.maxDevices = maxDevices;
        });
    }

    protected onSubmit = async (e: SubmitEvent): Promise<void> => {
        // Never actually submit the form anywhere, we handle submissions
        // asynchronously via the API.
        e.preventDefault();

        const formData = new FormData(this.element);
        if (!formData.has(this.TEAM_COUNT_INPUT_NAME)) {
            console.error('No team count input found!');
            toasts.make(
                'An unknown error occurred. Please contact a site administrator.',
                'error'
            );
            return;
        }

        const teamCountRaw: string =
            <string | null>formData.get(this.TEAM_COUNT_INPUT_NAME) || '0';
        const teamCount = parseInt(teamCountRaw, 10);

        if (!teamCount || isNaN(teamCount)) {
            toasts.make(
                'You need to select a number of teams to create for this game.',
                'warn'
            );
            return;
        }

        if (this.maxDevices && this.maxDevices < teamCount) {
            toasts.make(
                `Your player limit is ${this.maxDevices}, make sure not to create more teams than you can invite players.`,
                'warn'
            );
            return;
        }

        await this.sdk.createTeams(teamCount);
    };
}
