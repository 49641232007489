export default {
    'generic.confirm': {
        de: 'Okay',
        en: 'Confirm',
    },
    'generic.cancel': {
        de: 'Abbrechen',
        en: 'Cancel',
    },
    'generic.days': {
        de: 'Tage',
        en: 'days',
    },
    'alert.generic.error.retry.title': {
        de: 'Hoppla',
        en: 'Oops',
    },
    'alert.generic.error.retry.body': {
        de: 'Da ist leider etwas schiefgelaufen. Bitte lade die Seite neu und versuche es erneut.',
        en: 'Something went wrong. Please reload the page and try again.',
    },
    'confirmation.owner.join.body': {
        de: 'Es gibt immer noch Teams ohne Mitglieder. Wenn du dich jetzt einem Team anschließt, verlässt du diese Ansicht und kannst keine weiteren Personen in Teams einladen oder deine Codes verteilen. Bist du sicher, dass du fortfahren möchtest?',
        en: 'There are still teams without any members. If you join a team now, you will leave this view and will not be able to invite people into teams or distribute your codes. Are you sure you want to continue?"',
    },
    'confirmation.owner.join.title': {
        de: 'Bist du dir sicher?',
        en: 'Are you sure?',
    },
    'confirmation.owner.join.confirm': {
        de: 'Weiter',
        en: 'Continue',
    },
    'confirmation.owner.join.cancel': {
        de: 'Abbrechen',
        en: 'Cancel',
    },
    'confirmation.demo.start.title': {
        de: 'Gratis-Demo starten',
        en: 'Start free demo',
    },
    'confirmation.demo.start.body': {
        de: 'Die Demo kannst du mit einem Gerät in einer Stadt deiner Wahl spielen. Du hast eine Stunde, um so viele Challenges wie möglich zu absolvieren. Möchtest du dein Demo-Abenteuer jetzt beginnen?',
        en: 'You can play the demo with one device in a city of your choice. You have one hour to complete as many challenges as possible. Would you like to begin your demo adventure now?',
    },
    'confirmation.demo.start.confirm': {
        de: "Los geht's!",
        en: "Let's go!",
    },
    // 'confirmation.demo.start.cancel': {},
    'confirmation.team.start.generic.title': {
        de: 'Auf die Plätze...',
        en: 'Ready, steady...',
    },
    'confirmation.team.start.generic.body': {
        de: 'Ab hier gibt es kein Zurück und keine faulen Ausreden. Das Spiel beginnt. Bist du (und deine Mitspieler:innen) bereit?',
        en: 'Are you ready to start your adventure? Are you sure you want to start it now?',
    },
    'confirmation.team.start.generic.confirm': {
        de: 'Los!',
        en: 'Go!',
    },
    'confirmation.team.start.generic.cancel': {
        de: 'Abbrechen',
        en: 'Cancel',
    },
    'confirmation.team.start.noname.title': {
        de: 'Schall und Rauch',
        en: 'A Team With No Name',
    },
    'confirmation.team.start.noname.body': {
        de: 'Dein Team hat noch keinen Namen. Seid ihr sicher, dass ihr ohne Namen spielen wollt?',
        en: 'Your team does not have a name yet. Are you sure you want to play without a name?',
    },
    'confirmation.team.start.noname.confirm': {
        de: 'Weiter',
        en: 'Continue',
    },
    'confirmation.team.start.noname.cancel': {
        de: 'Abbrechen',
        en: 'Cancel',
    },
    'confirmation.team.leave.title': {
        de: 'Team verlassen und Spiel beenden?',
        en: 'Leave team and end game?',
    },
    'confirmation.team.leave.body': {
        de: 'Dies wird dein Spiel beenden. Bist du sicher, dass du das möchtest?',
        en: 'This will end your current game. Are you sure you want to continue?',
    },
    'confirmation.team.leave.confirm': {
        de: 'Team & Spiel verlassen',
        en: 'Leave team and game',
    },
    'confirmation.team.leave.cancel': {
        de: 'Abbrechen, weiterspielen',
        en: 'Cancel, continue playing',
    },
    'confirmation.team.leader.finish.title': {
        de: 'Spiel für das komplette Team beenden?',
        en: 'Finish the game for the entire team?',
    },
    'confirmation.team.leader.finish.body': {
        de: 'Ihr habt noch Zeit weiterzuspielen. Möchtest du dieses Spiel für dein Team wirklich beenden? Dies kann nicht rückgängig gemacht werden.',
        en: 'You still have some time left. Are you sure you want to finish this game for your entire team? This action can not be reversed.',
    },
    'confirmation.team.leader.finish.confirm': {
        de: 'Spiel beenden',
        en: 'Finish game',
    },
    'confirmation.team.leader.finish.cancel': {
        de: 'Abbrechen, weiterspielen',
        en: 'Cancel, continue playing',
    },
    // claim game, join team, claim voucher
    'confirmation.claim.game.title': {
        de: 'Spiel-Code "{{code}}" einlösen?',
        en: 'Claim game "{{code}}"?',
    },
    'confirmation.claim.game.body': {
        de: 'Hiermit übernimmst du das Spiel mit dem Code "{{code}}", womit du es starten kannst. Das Spiel wird dir zugewiesen und ist dann nicht mehr übertragbar. Möchtest du fortfahren?',
        en: 'You are about to claim the game with the code "{{code}}", allowing you to start that game. The game will be assigned to you, meaning no other player can host this game. This is not reversible. Continue?',
    },
    'confirmation.claim.game.confirm': {
        de: 'Ja, Spiel einlösen',
        en: 'Yes, claim game',
    },
    'confirmation.claim.game.cancel': {
        de: 'Abbrechen',
        en: 'Cancel',
    },
    'confirmation.claim.voucher.title': {
        de: 'Gutschein-Code "{{code}}" einlösen?',
        en: 'Claim voucher code "{{code}}"?',
    },
    'confirmation.claim.voucher.body': {
        de: 'Hiermit übernimmst du das Spiel mit dem Gutscheincode "{{code}}", womit du es starten kannst. Das Spiel wird dir zugewiesen und ist dann nicht mehr übertragbar. Möchtest du fortfahren?',
        en: 'You are about to claim the game with the voucher code "{{code}}", allowing you to start that game. The game will be assigned to you, meaning no other player can host this game. This is not reversible. Continue?',
    },
    'confirmation.claim.voucher.confirm': {
        de: 'Ja, Gutschein einlösen',
        en: 'Yes, claim voucher',
    },
    'confirmation.claim.voucher.cancel': {
        de: 'Abbrechen',
        en: 'Cancel',
    },
    'confirmation.join.team.title': {
        de: 'Team "{{code}}" beitreten?',
        en: 'Join team "{{code}}"?',
    },
    'confirmation.join.team.body': {
        de: 'Hiermit trittst du dem Team mit dem Code "{{code}}" bei. Eventuell noch vorhandene aktive Spiele oder Teams werden verlassen und können nicht wiederhergestellt werden. Möchtest du fortfahren?',
        en: 'You are about to join the team with the code "{{code}}". You will automatically leave and not be able to rejoin any other games or teams you might still be playing in. Continue?',
    },
    'confirmation.join.team.confirm': {
        de: 'Ja, Team beitreten',
        en: 'Yes, join team',
    },
    'confirmation.join.team.cancel': {
        de: 'Abbrechen',
        en: 'Cancel',
    },
    'confirmation.account.delete.title': {
        de: 'Account unwiderruflich löschen',
        en: 'Irreversibly delete account',
    },
    'confirmation.account.delete.body': {
        de: 'Hiermit kannst du deinen Account unwiderruflich löschen. Deine bei uns gespeicherten Daten werden auf dem Server gelöscht und können nicht wiederhergestellt werden. Bitte gib zur Bestätigung das Wort "LÖSCHEN" in das Textfeld ein, wenn du fortfahren möchtest.',
        en: 'This will delete your account irretrievably. Your data will be removed from our servers and can not be restored. In order to continue, type the word "DELETE" into the text input box and click the button.',
    },
    'confirmation.account.delete.confirm': {
        de: 'Account löschen',
        en: 'Delete account',
    },
    'confirmation.account.delete.cancel': {
        de: 'Abbrechen',
        en: 'Cancel',
    },
    'confirmation.account.delete.input-expected': {
        de: 'LÖSCHEN',
        en: 'DELETE',
    },
    'confirmation.account.delete.input-label': {
        de: 'Gib "LÖSCHEN" ein:',
        en: 'Type "DELETE":',
    },
    'confirmation.account.delete.input-validation-error': {
        de: 'Bitte überprüfe deine Eingabe.',
        en: 'Please double-check your input.',
    },
    'confirmation.join.team.and.archive.title': {
        de: 'Du bist derzeit noch in einem Spiel',
        en: 'You are currently still owner of a game',
    },
    'confirmation.join.team.and.archive.body': {
        de: 'Ein von dir gestartetes Spiel ist derzeit noch aktiv. Wenn du fortfährst werden alle aktiven Spieler aus diesem Spiel geworfen es automatisch archiviert und kann nicht wiederhergestellt werden. Sicher, dass du dein aktuelles Spiel beenden und stattdessen dem Team "{{code}}" beitreten möchtest?',
        en: 'A game you own and started is currently active. If you continue all players active in this game will be removed and the game will be irreversibly archived. Are you certain you want to end your current game and join team "{{code}}" instead?',
    },
    'confirmation.join.team.and.archive.confirm': {
        de: 'Ja, Spiel archivieren & Team beitreten',
        en: 'Yes, archive game and join team',
    },
    'confirmation.join.team.and.archive.cancel': {
        de: 'Abbrechen',
        en: 'Cancel',
    },
    'confirmation.qr.scan.invalid.title': {
        de: 'Scan fehlgeschlagen',
        en: 'Scan failed',
    },
    'confirmation.qr.scan.invalid.body': {
        de: 'Dies ist leider kein gültiger Urban Challenger Code. Bitte versuche es noch einmal oder wende dich an den Support.',
        en: 'Unfortunately this is not a valid Urban Challenger code. Please try again or contact support.',
    },
    'confirmation.qr.scan.invalid.confirm': {
        de: 'Noch einmal versuchen',
        en: 'Retry',
    },
    'confirmation.qr.scan.invalid.cancel': {
        de: 'Abbrechen',
        en: 'Cancel',
    },
    'confirmation.owner.game.end.title': {
        de: 'Spiel sofort für alle beenden?',
        en: 'Finish game for everyone immediately?',
    },
    'confirmation.owner.game.end.body': {
        de: 'Dies wird sofort das Spiel für alle Teilnehmer beenden, Teams, die ihr Spiel möglicherweise noch nicht abgeschlossen haben mit eingeschlossen. Dies kann nicht rückgängig gemacht werden. Fortfahren?',
        en: 'This will immediately end the game for everyone, including team that might not have finished yet. It can not be undone. Are you sure you want to proceed?',
    },
    'confirmation.owner.game.end.confirm': {
        de: 'Für alle beenden',
        en: 'End for everyone',
    },
    'confirmation.owner.game.end.cancel': {
        de: 'Abbrechen',
        en: 'Cancel',
    },
    'error.scan.qr.failed': {
        de: 'Scan fehlgeschlagen: Ungültiger Code',
        en: 'Scan failed: Invalid Code',
    },
    'mail.invite.subject': {
        de: 'Tritt meinem Team bei Urban Challenger bei!',
        en: 'Join my Urban Challenger team!',
    },
    'team-photo.crop.submit': {
        de: 'Team-Foto aktualisieren',
        en: 'Update team photo',
    },
};
