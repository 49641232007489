const UNIT_PRICE = 9;

export async function pricingSelect(containers: NodeListOf<HTMLElement>) {
    let resolveMemberStack: (ms) => void;
    const memberstack: Promise<Memberstack> = new Promise((res) => {
        resolveMemberStack = res;
    });

    const timer = window.setInterval(() => {
        if (window.$memberstackDom) {
            window.clearInterval(timer);
            resolveMemberStack(window.$memberstackDom);
        }
    }, 200);

    const ms = await memberstack;
    const rawPlans = await ms.getPlans();
    const plans = rawPlans?.data;

    if (!plans) {
        throw 'Failed to initialize memberstack plans';
    }

    // const containers = document.querySelectorAll<HTMLElement>(
    //     '[data-game-component="payment"]'
    // );

    containers.forEach((c) => {
        const selectElements = c.querySelectorAll<HTMLElement>(
            '[data-uc-device-count]'
        );
        const button = c.querySelector<HTMLElement>('[data-payment-button]');
        const display = c.querySelector<HTMLElement>(
            '[data-game-component="selection-display"]'
        );

        const planId = c.dataset.ucPlanId;
        const matchingPlan = plans.find((p) => p.id === planId);
        const planPrices = matchingPlan?.prices;

        if (!planPrices) {
            return;
        }

        const simplePrices = new Map<number, string>();
        planPrices.forEach((price) => {
            const units = price.amount / UNIT_PRICE;
            simplePrices.set(units, price.id);
        });

        if (selectElements.length && button) {
            selectElements.forEach((e) => {
                e.addEventListener(
                    'click',
                    getOnClick(e, button, display, simplePrices)
                );
            });
        }

        button?.setAttribute('data-ms-price:add', simplePrices.get(1));
    });
}

function getOnClick(
    e: HTMLElement,
    button: HTMLElement | null,
    display: HTMLElement | null,
    planPrices: Map<number, string>
) {
    return (ev: MouseEvent) => {
        ev.preventDefault();

        // Memberstack might have deleted the button
        if (!button) {
            return;
        }

        const unitsRaw = e.dataset.ucDeviceCount;

        if (!unitsRaw) {
            return;
        }

        const units = parseInt(unitsRaw, 10);

        if (!units || isNaN(units) || !planPrices.has(units)) {
            return;
        }

        const priceId = planPrices.get(units);
        const text = e.innerText;

        if (priceId) {
            button.setAttribute('data-ms-price:add', priceId);
        }

        if (text && display) {
            display.innerText = text;
        }

        // Close dropdown again once a value has been selected
        if (display && display.parentElement) {
            const parent = display.parentElement;
            const grandparent = parent?.parentElement;

            if (parent && parent.matches('.w-dropdown')) {
                parent.dispatchEvent(new CustomEvent('w-close'));
            }
            if (grandparent && grandparent.matches('.w-dropdown')) {
                grandparent.dispatchEvent(new CustomEvent('w-close'));
            }
        }
    };
}
