import type { GameApi, Challenge as RawChallenge } from 'urban-challenger-sdk';
import type { Challenge, ChallengeSlideshowEvents } from './types';
import type { Emitter } from 'mitt';

import mitt from 'mitt';
import Swiper from 'swiper';
import { EffectCards, Virtual } from 'swiper/modules';
import _toLower from 'lodash-es/toLower';
import _words from 'lodash-es/words';
import _capitalize from 'lodash-es/capitalize';
import { debug } from '@gebruederheitz/debuggable';
import { EnhancedElement } from '@gebruederheitz/energize';

import { SlideRenderer } from './slide-renderer';
import { BackgroundUpdater } from './background-updater';
import { CategorySorter } from './category-sorter';

export class ChallengeSlideshow {
    private readonly debug = debug.spawn(this);
    protected readonly containerCopy: EnhancedElement<HTMLElement>;
    protected readonly renderer: SlideRenderer;
    protected readonly backgroundUpdater: BackgroundUpdater;
    protected readonly eventProxy: Emitter<ChallengeSlideshowEvents> = mitt();

    protected slideshow: Swiper | null = null;
    protected slides: Challenge[];
    protected originalSlides: Challenge[];
    protected slideshowContainer: EnhancedElement<HTMLElement>;
    protected slideFilters: ((c: Challenge[]) => Challenge[])[] = [];
    protected initialized: boolean = false;

    constructor(
        protected selector: string = '[data-game-challenge="container"]',
        protected sdk: GameApi,
        protected isNative: boolean
    ) {
        this.slideshowContainer = EnhancedElement.fromSelector(selector);
        this.containerCopy = this.slideshowContainer.clone();

        this.renderer = new SlideRenderer(sdk, isNative);
        this.backgroundUpdater = new BackgroundUpdater(
            this.slideshowContainer.parent()
        );
    }

    public async init() {
        this.debug.log('init start');
        this.slides = this.originalSlides = await this.getChallenges();

        this.addFilter(CategorySorter.sort);
        this.filterSlides(false);
        this.initSlideshow();

        this.eventProxy.emit('init', this.slides);
        this.initialized = true;
    }

    public isInitialized(): boolean {
        return this.initialized;
    }

    public forceUpdate = () => {
        this.debug.log('forced update');
        this.filterSlides();
    };

    public scrollTo(slug: string) {
        const index = this.slides.findIndex((e) => e.slug === slug);

        if (index > -1) {
            this.slideshow.slideToLoop(index);
        }
    }

    public scrollToCategory(categorySlug: string) {
        const i = this.slides.findIndex((e) => e.categorySlug === categorySlug);
        if (i > -1) {
            this.slideshow.slideToLoop(i);
        }
    }

    public get on() {
        return this.eventProxy.on;
    }

    public addFilter(cb: (c: Challenge[]) => Challenge[]) {
        this.slideFilters.push(cb);
    }

    public getSlides(): Challenge[] {
        return this.slides;
    }

    protected onSlideChanged = () => {
        this.updateBackgroundColor();
        this.eventProxy.emit(
            'slide-change',
            this.slideshow?.virtual?.slides[this.slideshow.realIndex] || null
        );
    };

    protected filterSlides(reInit: boolean = true) {
        this.debug.log(
            'filter slides',
            [...this.originalSlides],
            [...this.slides]
        );

        let slides = [...this.originalSlides];
        this.slideFilters.forEach((hook) => {
            slides = hook(slides);
        });

        this.debug.log('filtered slides', slides);

        this.slides = slides;
        if (reInit) {
            this.reinit();
        }

        this.debug.log('filterSlides', this.slideshow);

        this.eventProxy.emit('slides-filtered', {
            slides: this.slides,
            currentSlide:
                this.slideshow?.virtual.slides[this.slideshow.realIndex] ||
                null,
        });
    }

    protected async getChallenges(): Promise<Challenge[]> {
        let gameSlug = this.slideshowContainer.dataset.game || null;

        if (!gameSlug) {
            gameSlug = window.location.pathname.split('/').pop();
        }

        if (!gameSlug) {
            this.debug.error('game not defined!');
            return;
        }

        const rawChallenges: RawChallenge[] = await this.sdk.getChallenges(
            gameSlug
        );
        return rawChallenges.map((slide): Challenge => {
            const category = this.parseCategory(slide.category);
            return {
                ...slide,
                proof: this.parseProofType(slide.proof),
                category: category,
            };
        });
    }

    protected parseProofType(proof: string): Challenge['proof'] {
        proof = _toLower(proof);

        switch (proof) {
            case 'video':
            case 'audio':
            case 'photo':
                return proof;
            case 'text':
            default:
                return 'text';
        }
    }

    protected parseCategory(category: string): Challenge['category'] {
        const categoryParts = _words(category);
        const ucCategoryParts = categoryParts.map((w) => _capitalize(w));
        category = ucCategoryParts.join(' ');

        switch (category) {
            case 'Time Traveller':
            case 'Time Traveler':
                return 'Time Traveler';
            case 'Nature Lover':
            case 'Connector':
            case 'Foodie':
            case 'Artist':
                return category;
            case 'Explorer':
            default:
                return 'Explorer';
        }
    }

    protected initSlideshow(): void {
        this.slideshow = new Swiper(this.slideshowContainer.getElement(), {
            modules: [Virtual, EffectCards],
            effect: 'cards',
            grabCursor: true,
            loop: true,
            keyboard: true,
            cardsEffect: {
                slideShadows: false,
                // perSlideOffset: 8,
                // perSlideRotate: 2,
                // rotate: true,
            },
            freeMode: false,
            virtual: {
                enabled: true,
                // @NB: breaks loop rendering
                // addSlidesAfter: 8,
                // addSlidesBefore: 4,
                cache: true,
                renderSlide: this.renderer.render,
                slides: this.slides,
            },
            on: {
                init: this.updateBackgroundColor,
                activeIndexChange: this.onSlideChanged,
            },
        });

        this.debug.log('initSlideshow', this.slideshow);
    }

    protected updateBackgroundColor = () => {
        this.backgroundUpdater.update(this.slideshow, this.slides);
    };

    protected reinit() {
        this.debug.log('Re-Init');
        const currentChallenge =
            (this.slideshow?.realIndex &&
                this.slideshow.virtual.slides[this.slideshow.realIndex].slug) ||
            null;
        this.debug.log('memo', currentChallenge);

        this.slideshow?.destroy();
        const newSlideshowContainer = this.containerCopy
            .clone()
            .insertAfter(this.slideshowContainer);
        this.slideshowContainer.destroy();
        this.slideshowContainer = newSlideshowContainer;
        this.initSlideshow();

        if (currentChallenge) {
            this.debug.log('scroll', this.slides);
            const index = this.slides.findIndex(
                (e) => e.slug === currentChallenge
            );
            this.debug.log('memo', index);
            if (index > -1) {
                this.debug.log('scrolling to', this.slides[index].slug);
                this.slideshow.slideToLoop(index);
            }
        }

        this.debug.log('reinit', this.slideshow);
    }
}
