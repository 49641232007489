import type { GameApi } from 'urban-challenger-sdk';
import type { Challenge } from './types';

import { EnhancedElement } from '@gebruederheitz/energize';

import { SubmissionAndStatus } from '../submission-and-status';

export class ChallengeCard extends EnhancedElement<HTMLElement, GameApi> {
    constructor(
        element: HTMLElement,
        protected challenge: Challenge,
        protected isNative: boolean
    ) {
        super(element);
    }

    public init(): this {
        this.element.removeAttribute('data-game-challenge');
        this.setAttribute('href', `/challenge/${this.challenge.slug}`);
        this.setAttribute('data-category', this.challenge.categorySlug);
        this.findAndWrap('[data-game-challenge="title"]')?.content(
            this.challenge.title
        );
        this.findAndWrap('img')?.setAttribute('src', this.challenge.image);
        this.findAndWrap('[data-game-challenge="description"]')?.content(
            this.challenge.description
        );
        this.findAndWrap('[data-game-challenge="long-description"]')?.content(
            this.challenge.longDescription
        );

        this.findAndWrap('[data-game-challenge="category"')?.contentOrHide(
            this.challenge.category
        );
        this.findAndWrap('[data-game-challenge="points"]')?.content(
            this.challenge.points.toString(10)
        );

        // @HACK:
        //  The aim should ideally be to not rely on the frontend for scoring
        //  but the read the points directly from the source in the API backend.
        new SubmissionAndStatus(
            this.element,
            this.challenge.slug,
            this.challenge.points
        )
            .connect(this.store)
            .init(this.isNative)
            .then();

        return this;
    }
}

export class ModalChallengeCard extends ChallengeCard {
    public override init(): this {
        super.init();
        this.findAndWrap('[data-game-challenge="task"]')?.contentOrHide(
            this.challenge.task || ''
        );

        this.findAndWrap(
            `[data-game-challenge="${this.challenge.proof}"]`
        )?.show();

        const locations = this.findAndWrap('[data-game-challenge="locations"]');
        if (locations) {
            const template = locations
                .findAndWrap('[data-game-challenge="location"]')
                .hide();

            this.challenge.locations.forEach((l) => {
                template
                    .clone()
                    .content(l.title)
                    .setAttribute('href', l.url)
                    .appendTo(locations)
                    .show();
            });
        }

        if (this.challenge.freemium) {
            this.findAndWrap('[data-game-challenge="freemium"]')?.show();
        }

        if (this.challenge.hashtag) {
            this.findAndWrap('[data-game-challenge="hashtag"]')
                ?.content(this.challenge.hashtag)
                .show();

            const sharingSection = this.findAndWrap(
                '[data-game-challenge="sharing"]'
            )?.show();

            sharingSection
                ?.findAndWrapAll<HTMLAnchorElement>('a.share_item')
                ?.forEach((i) => {
                    const href = i.getElement().href;
                    href.replace(
                        '{{share-url}}',
                        `${window.location.origin}/challenge/${this.challenge.slug}`
                    );
                    href.replace('{{hashtag}}', this.challenge.hashtag);

                    i.setAttribute('href', href);
                });
        }

        return this;
    }
}
