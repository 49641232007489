import type { GameApi, Writable } from 'urban-challenger-sdk';

import { debug, DebugHelper } from '@gebruederheitz/debuggable';
import { EnhancedElement } from '@gebruederheitz/energize';

import { TaskState, TaskStateStore } from './index';

export class SubmissionForm extends EnhancedElement<
    HTMLFormElement,
    TaskStateStore,
    TaskState
> {
    private debug: DebugHelper;
    private resubmitActive: boolean = false;
    private spinner: EnhancedElement<HTMLCustomSpinnerElement> | null = null;
    private lastState: TaskState = TaskState.OPEN;

    constructor(
        e,
        private readonly taskSlug: string,
        private readonly taskPoints: number,
        private readonly sdk: GameApi,
        private resubmitActiveStore: Writable<boolean>,
        isNative: boolean
    ) {
        super(e);

        this.debug = debug.spawn('SubmissionForm:' + this.taskSlug);
        resubmitActiveStore.subscribe(async (isActive) => {
            this.debug.log('resubmit active state change', isActive);
            this.resubmitActive = isActive;

            this.onStateUpdate(this.lastState);
        });

        this.on('submit', this.onSubmit);

        if (isNative) {
            this.findAll('uc-file-upload').forEach((e) => {
                e.setAttribute('native', 'true');
            });
        }
    }

    protected createSpinner(): void {
        this.spinner = EnhancedElement.fromDefinition<'uc-spinner'>({
            type: 'uc-spinner',
            attributes: {
                inset: 'true',
                size: '4',
                thickness: '1.5',
                color: 'var(--dark-red, #ff3600)',
            },
            parent: this.element,
        });
    }

    protected onSubmit = async (submitEvent: SubmitEvent): Promise<void> => {
        submitEvent.preventDefault();
        this.debug.log('form submit');

        this.addClass('busy');
        if (!this.spinner) {
            this.createSpinner();
        }
        this.spinner.setAttribute('disabled', 'false');

        const data = new FormData(this.element);

        let inputName: string | null = null;
        const input = this.find<HTMLInputElement>(
            '[name="proof-text"], [name^="proof-file"]'
        );
        if (input) {
            inputName = input.name;
        }

        await this.sdk.completeTask(
            this.taskSlug,
            this.taskPoints,
            data,
            inputName
        );

        this.removeClass('busy');
        this.spinner.setAttribute('disabled', 'true');
        this.resubmitActiveStore.update(() => false);
    };

    protected onStateUpdate(state: TaskState) {
        this.debug.log('form state update', state);

        this.lastState = state;

        switch (state) {
            case TaskState.OPEN:
                this.show();
                break;
            default:
                this.resubmitActive ? this.show() : this.hide();
        }
    }
}
