import { GameApi, toasts } from 'urban-challenger-sdk';
import { confirmPrintf } from '../util/confirm';
import { checkUrlForCode } from '../util/url-parameters';

export class ClaimGame {
    // Requires a form element containing the input element for entering the
    // game code and a submit button; the input needs to have the following name
    // attribute:
    public CODE_INPUT_NAME: string = 'claim-game-code';
    protected errorMessageNoCode =
        'You need to enter a code in order to claim a game.';

    constructor(
        protected readonly form: HTMLFormElement,
        protected readonly sdk: GameApi,
        protected urlParameterName = 'uc-game',
        protected confirmationMessage = 'confirmation.claim.game'
    ) {
        this.form.addEventListener('submit', this.onSubmit);
        checkUrlForCode(this.urlParameterName, this.onValidCodeFound).then();
    }

    protected onValidCodeFound = async (gameCode: string): Promise<boolean> => {
        if (
            await confirmPrintf(this.confirmationMessage, 'question', {
                '{{code}}': gameCode,
            })
        ) {
            return this.doApiRequest(gameCode);
        }

        return false;
    };

    protected onSubmit = async (e: SubmitEvent): Promise<void> => {
        // Never actually submit the form anywhere, we handle submissions
        // asynchronously via the API.
        e.preventDefault();

        const formData = new FormData(this.form);
        if (!formData.has(this.CODE_INPUT_NAME)) {
            console.error('No claim code input found!');
            toasts.make(
                'An unknown error occurred. Please contact a site administrator.',
                'error'
            );
            return;
        }

        const code: string | null = <string | null>(
            formData.get(this.CODE_INPUT_NAME)
        );

        if (!code) {
            toasts.make(this.errorMessageNoCode, 'warn');
            return;
        }

        if (code.length !== 6) {
            toasts.make('Invalid code!', 'warn');
            return;
        }

        await this.doApiRequest(code);
    };

    protected async doApiRequest(code): Promise<boolean> {
        const r = await this.sdk.claimGame(code);
        return r !== null;
    }
}

export class ClaimVoucher extends ClaimGame {
    public override CODE_INPUT_NAME: string = 'claim-voucher-code';
    protected override errorMessageNoCode =
        'You need to enter a code in order to claim your voucher.';

    constructor(form: HTMLFormElement, sdk: GameApi) {
        super(form, sdk, 'uc-voucher', 'confirmation.claim.voucher');
    }

    protected override async doApiRequest(code): Promise<boolean> {
        const redirectUrl = await this.sdk.claimVoucher(code);

        if (redirectUrl) {
            window.setTimeout(() => {
                window.location.replace(redirectUrl);
            }, 1000);
        }

        return redirectUrl !== null;
    }
}
