import type { Writable } from 'urban-challenger-sdk';
import type { TaskStateStore } from './index';

import { EnhancedElement } from '@gebruederheitz/energize';

import { TaskState } from './index';

export class ResubmitButton extends EnhancedElement<
    HTMLButtonElement,
    TaskStateStore
> {
    protected resubmissionActive: boolean = false;
    private lastState: TaskState = TaskState.OPEN;

    constructor(e, protected resubmitActiveStore: Writable<boolean>) {
        super(e);
        this.on('click', this.onClick);
        resubmitActiveStore.subscribe((active) => {
            this.resubmissionActive = active;
            this.onStateUpdate(this.lastState);
        });
    }

    protected onClick = (e: MouseEvent) => {
        e.preventDefault();
        this.resubmitActiveStore.update(() => true);
        this.hide();
    };

    protected onStateUpdate(state: TaskState) {
        this.lastState = state;
        if (state !== TaskState.OPEN && !this.resubmissionActive) {
            this.show();
        } else {
            this.hide();
        }
    }
}
