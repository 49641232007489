import type { ApiState, GameApi, Team } from 'urban-challenger-sdk';
import { EnhancedElement } from '@gebruederheitz/energize';

import { confirm } from '../util/confirm';

export class LeaveTeam extends EnhancedElement<any> {
    protected previousTeam: Team | null = null;

    constructor(element, protected sdk: GameApi) {
        super(element);
        this.hide();
        this.sdk.subscribe(this.onStateUpdate);
        this.on('click', this.onClick);
    }

    protected onClick = async (e: MouseEvent): Promise<void> => {
        e.preventDefault();

        if (!this.previousTeam) {
            return;
        }

        if (await confirm('confirmation.team.leave')) {
            await this.sdk.leaveTeam();
        }
    };

    protected onStateUpdate = ({ team, maxDevices }: ApiState): void => {
        const isSinglePlayer = maxDevices === 1;

        if (team && !isSinglePlayer && !this.previousTeam) {
            this.previousTeam = team;
            this.show();
        }

        if (!team && this.previousTeam) {
            this.previousTeam = team;
            this.hide();
        }
    };
}
