import type { GameApi } from 'urban-challenger-sdk';
import type { Challenge } from './types';

import { createElement } from 'urban-challenger-sdk';
import { debug } from '@gebruederheitz/debuggable';
import { EnhancedElement } from '@gebruederheitz/energize';

import { ChallengeCard, ModalChallengeCard } from './challenge-card';

export class SlideRenderer {
    protected readonly debug = debug.spawn(this);
    protected readonly slideTemplate: EnhancedElement<HTMLElement>;
    protected readonly modalTemplate: EnhancedElement<HTMLElement>;

    protected previousModal: HTMLElement = null;

    constructor(
        protected readonly sdk: GameApi,
        protected readonly isNative: boolean
    ) {
        this.slideTemplate = EnhancedElement.fromSelector(
            '[data-game-challenge="slide-template"]'
        );
        this.modalTemplate = EnhancedElement.fromSelector(
            '[data-game-challenge="card-template"]'
        );
        this.debug.log(this.slideTemplate);
    }

    public render = (slide: Challenge): HTMLElement => {
        this.debug.log('render', slide);
        const card = this.slideTemplate
            .cloneInto(ChallengeCard, slide, this.isNative)
            .connect(this.sdk)
            .init();

        card.on('click', (e) => {
            e.preventDefault();
            this.renderModal(slide);
        });

        return createElement({
            classNames: [
                'swiper-slide',
                'challenge',
                `category-${slide.categorySlug}`,
            ],
            children: [card.getElement()],
        });
    };

    protected renderModal(slide: Challenge) {
        if (
            this.previousModal &&
            this.previousModal.dataset.task === slide.slug
        ) {
            this.previousModal.setAttribute('open', 'true');
        } else {
            if (this.previousModal) {
                this.previousModal.remove();
            }
            const card = this.modalTemplate
                .cloneInto(ModalChallengeCard, slide, this.isNative)
                .connect(this.sdk)
                .init();

            this.previousModal = createElement({
                type: 'gh-modal',
                attributes: {
                    open: 'true',
                    'data-task': slide.slug,
                    'scroll-outside': 'true',
                },
                children: [card.getElement()],
                parent: document.body,
            });
        }
    }
}
