import type { GameApi } from 'urban-challenger-sdk';

import { createElement } from 'urban-challenger-sdk';
import Cropper from 'cropperjs';
import { EnhancedElement } from '@gebruederheitz/energize';

import { localize } from '../util/i18n/localize';

import 'cropperjs/dist/cropper.min.css';

export class TeamPhotoCropper {
    protected cropper: Cropper;
    protected modal: HTMLCustomModalElement;

    constructor(
        protected readonly sdk: GameApi,
        protected readonly teamCode: string
    ) {}

    public init = async (imageSource: string) => {
        const img = createElement<HTMLImageElement>({
            type: 'IMG',
            classNames: ['uc-cropper__image'],
            attributes: {
                src: imageSource,
            },
        });

        const imgContainer = createElement({
            classNames: ['uc-cropper__image-container'],
            children: [img],
        });

        const container = createElement({
            classNames: ['uc-cropper'],
            children: [imgContainer, this.getButton().getElement()],
        });

        this.modal = createElement<HTMLCustomModalElement>({
            type: 'gh-modal',
            parent: document.body,
            children: [container],
            attributes: {
                open: 'true',
                name: 'cropper',
            },
        });

        this.modal.addEventListener(
            'visibilitychange',
            ({ detail: { visible } }: CustomEvent) => {
                console.log('visibilitychange', visible);
                !visible && this.modal.remove();
            }
        );

        this.cropper = new Cropper(img, {
            aspectRatio: 1,
            dragMode: 'move',
            autoCropArea: 1,
            cropBoxMovable: false,
            cropBoxResizable: false,
            toggleDragModeOnDblclick: false,
        });
    };

    protected getButton() {
        const button = EnhancedElement.fromDefinition({
            type: 'button',
            classNames: ['btn', 'btn-primary', 'button', 'uc-cropper__submit'],
            content: localize('team-photo.crop.submit'),
        });

        button.on('click', this.onSubmit);

        return button;
    }

    protected onSubmit = () => {
        this.cropper
            .getCroppedCanvas({ fillColor: '#fff' })
            .toBlob(async (blob) => {
                const formData = new FormData();
                formData.append('file', blob, 'team.jpg');
                await this.sdk.uploadTeamPhoto(this.teamCode, formData);
            }, 'image/jpeg');

        this.modal.hide();
    };
}
