import type { TaskState, TaskStateStore } from './index';

import { EnhancedElement } from '@gebruederheitz/energize';

export class TaskStatusDisplayComponent extends EnhancedElement<
    HTMLElement,
    TaskStateStore
> {
    public static makeAll(
        parentElement: Document | Element = window.document,
        componentName: string,
        displayWhenStatusEquals: TaskState,
        store: TaskStateStore
    ): TaskStatusDisplayComponent[] {
        return Array.from(
            parentElement.querySelectorAll(
                `[data-uc-task-component="${componentName}"]`
            )
        ).map((element) => {
            const enhanced = new TaskStatusDisplayComponent(
                element,
                displayWhenStatusEquals
            );
            enhanced.connect(store);

            return enhanced;
        });
    }

    constructor(e, private displayWhenStatusEquals: TaskState) {
        super(e);
    }

    protected onStateUpdate(state: TaskState) {
        if (state === this.displayWhenStatusEquals) {
            this.show();
        } else {
            this.hide();
        }
    }
}
