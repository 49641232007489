import { ApiState, Team, UserStatus } from 'urban-challenger-sdk';
import _differenceBy from 'lodash-es/differenceBy';

import { BaseLobby } from './base';
import { TeamInvitation } from '../team-invitation';
import { CreateTeams } from '../create-teams';

export class OwnerLobby extends BaseLobby {
    protected previousUserState: UserStatus = UserStatus.INITIAL;
    protected previousTeams: Team[] | null = null;
    protected teamsForm: CreateTeams | null = null;
    protected unsubscribe: () => void = () => {};
    protected invites: Record<string, TeamInvitation> = {};

    public override show() {
        super.show();
        if (!this.teamsForm) {
            const form = this.rootElement.querySelector<HTMLFormElement>(
                'form[data-create-teams]'
            );
            this.teamsForm = new CreateTeams(form, this.sdk);
        }

        this.unsubscribe = this.sdk.subscribe(
            ({ status, teams = [] }: ApiState) => {
                if (
                    status !== this.previousUserState ||
                    _differenceBy(teams, this.previousTeams, 'code').length
                ) {
                    this.previousUserState = status;
                    this.previousTeams = teams;
                    if (status === UserStatus.OWNER_LOBBY) {
                        if (teams?.length) {
                            this.teamsForm?.hide();
                            this.renderTeamLeaderInvites(teams);
                        } else {
                            this.resetInvites();
                            this.initTeamsSetupForm();
                        }
                    } else {
                        this.resetInvites();
                    }
                }
            }
        );
    }

    public override hide(): this {
        super.hide();
        this.resetInvites();
        this.unsubscribe();

        return this;
    }

    protected resetInvites(): this {
        Object.values(this.invites).forEach((i) => i.destroy());
        this.invites = {};

        return this;
    }

    protected initTeamsSetupForm(): void {
        if (!this.teamsForm) {
            const form = this.rootElement.querySelector<HTMLFormElement>(
                'form[data-create-teams]'
            );
            this.teamsForm = new CreateTeams(form, this.sdk);
            this.teamsForm.show();
        } else if (!this.teamsForm.isVisible()) {
            this.teamsForm.show();
        }
    }

    protected renderTeamLeaderInvites(teams: Team[]): void {
        if (teams && teams.length) {
            const specificInvitationContainer = this.rootElement.querySelector(
                '[data-game-component="invitations"]'
            );
            const container = specificInvitationContainer || this.rootElement;

            teams.forEach((team) => {
                if (!this.invites[team.code]) {
                    this.invites[team.code] = TeamInvitation.fromTemplate(
                        [container, this.rootElement, document],
                        team,
                        this.sdk
                    ).appendTo(container);
                }
            });
        }
    }
}
