import type { UserStatusResponseObject } from './state';
import type { ModerationStatusResponseObject } from './moderation/state';

import { Route } from './util/route';

export const URLS = {
    LOCAL: 'https://game-api.urbanchallenger.ddev.site',
    DEV: 'https://stage.urbanchallenger.com',
    PROD: 'https://api.urbanchallenger.com',
    NEXT: 'https://next.api.urbanchallenger.com',
};

export enum HttpVerb {
    GET,
    POST_JSON,
    POST_FORM,
    DELETE,
    PUT,
    PATCH,
}

interface DemoResponse {
    token: string;
    status: UserStatusResponseObject;
}

export interface GameVariant {
    id?: number;
    planId: string;
    redirectPath: string;
    prettyName: string;
}

export interface UserRoleMapping {
    id?: number;
    userId: string;
    role: 'user' | 'moderator' | 'admin';
    alias: string | null;
}

export interface ChallengeLocation {
    title: string;
    url: string;
}
export interface Challenge {
    name: string;
    title: string;
    category: string;
    slug: string;
    image?: string;
    description: string;
    longDescription?: string | null;
    locations?: ChallengeLocation[];
    links?: string[];
    task: string;
    points: number;
    proof: string;
    freemium: boolean;
    hashtag?: string;
    id: string;
    categorySlug: string;
}

export interface UserChallenge {
    text: string;
    teamName: string;
    submittedBy: string;
    completedAt: string;
}

export interface Score {
    score: number;
    teamName: string;
    endtime: string;
    gameAlias: string;
}

export const Routes = {
    CLAIM_GAME: Route.make<UserStatusResponseObject, { game_code: string }>(
        'game/claim',
        HttpVerb.POST_JSON
    ),
    CLAIM_VOUCHER: Route.make<UserStatusResponseObject, { code: string }>(
        'voucher/claim',
        HttpVerb.POST_JSON
    ),
    CREATE_TEAMS: Route.make<
        UserStatusResponseObject,
        {
            team_count: number;
        }
    >('game/teams', HttpVerb.POST_JSON),
    END_AND_ARCHIVE: Route.make<UserStatusResponseObject>(
        'game/archive',
        HttpVerb.POST_JSON
    ),
    JOIN_TEAM: Route.make<UserStatusResponseObject, null, [string]>(
        (code: string) => `team/${code}/join`
    ),
    LEAVE_TEAM: Route.make<UserStatusResponseObject, null, [string]>(
        (code: string) => `team/${code}/leave`
    ),
    ME: Route.make<UserStatusResponseObject>('me'),
    DELETE_ACCOUNT: Route.make<boolean>('me/account', HttpVerb.DELETE),
    MODERATE: Route.make<ModerationStatusResponseObject>('moderate/status'),
    MOD_ARCHIVE_GAME: Route.make<
        ModerationStatusResponseObject,
        null,
        [string]
    >((gameId: string) => `moderate/game/${gameId}`, HttpVerb.DELETE),
    MOD_CREATE_GAME: Route.make<ModerationStatusResponseObject>(
        'game/create/manual/simple',
        HttpVerb.POST_FORM
    ),
    MOD_CREATE_GAME_V7: Route.make<ModerationStatusResponseObject>(
        'moderate/game/create/manual/simple',
        HttpVerb.POST_FORM
    ),
    MOD_CREATE_GAME_ADVANCED: Route.make<{
        message: string;
        id: string;
        code: string;
    }>('game/create/manual', HttpVerb.POST_FORM),
    MOD_CREATE_GAME_ADVANCED_V7: Route.make<{
        message: string;
        id: string;
        code: string;
    }>('moderate/game/create/manual', HttpVerb.POST_FORM),
    MOD_GAME_DECLARE_RESULTS: Route.make<
        ModerationStatusResponseObject,
        null,
        [string]
    >((gameId) => `moderate/game/${gameId}/results`, HttpVerb.POST_JSON),
    MOD_GAME_UNDO_DECLARE_RESULTS: Route.make<
        ModerationStatusResponseObject,
        null,
        [string]
    >((gameId) => `moderate/game/${gameId}/results`, HttpVerb.DELETE),
    MOD_GAME_ADD_TEAM: Route.make<
        ModerationStatusResponseObject,
        null,
        [string]
    >((gameId: string) => `moderate/game/${gameId}/team`, HttpVerb.POST_JSON),
    MOD_GAME_EDIT: Route.make<ModerationStatusResponseObject, null, [string]>(
        (gameId) => `moderate/game/${gameId}`,
        HttpVerb.PATCH
    ),
    MOD_TASK_APPROVE: Route.make<
        ModerationStatusResponseObject,
        null,
        [string]
    >((id: string) => `moderate/${id}/approve`, HttpVerb.PATCH),
    MOD_TASK_APPROVE_V7: Route.make<
        ModerationStatusResponseObject,
        null,
        [string]
    >((id: string) => `moderate/task/${id}/approve`, HttpVerb.PATCH),
    MOD_TASK_REJECT: Route.make<ModerationStatusResponseObject, null, [string]>(
        (id: string) => `moderate/${id}/reject`,
        HttpVerb.PATCH
    ),
    MOD_TASK_REJECT_V7: Route.make<
        ModerationStatusResponseObject,
        null,
        [string]
    >((id: string) => `moderate/task/${id}/reject`, HttpVerb.PATCH),
    MOD_GET_PLANS: Route.make<GameVariant[]>('moderate/plans'),
    DEPRECATED_START_GAME: Route.make<
        UserStatusResponseObject,
        { teamName: string }
    >('team/start', HttpVerb.POST_JSON),
    START_GAME: Route.make<UserStatusResponseObject>(
        'team/start',
        HttpVerb.POST_JSON
    ),
    UPDATE_TEAM_NAME: Route.make<
        UserStatusResponseObject,
        { teamName: string }
    >('team/name', HttpVerb.POST_JSON),
    START_DEMO: Route.make<DemoResponse>('demo/start'),
    STOP_GAME: Route.make<UserStatusResponseObject>('team/stop'),
    TASK_MARK_COMPLETED: Route.make<UserStatusResponseObject, FormData>(
        'task',
        HttpVerb.POST_FORM
    ),
    TeamPhotoUpload: Route.make<UserStatusResponseObject, null, [string]>(
        (code) => `team/${code}/photo/upload`,
        HttpVerb.POST_FORM
    ),
    TeamPhotoSelection: Route.make<
        { taskId: number; imageUrl: string }[],
        null,
        [string]
    >((code) => `team/${code}/photo/selection`),
    GetChallenges: Route.make<Challenge[], null, [string]>(
        (gameSlug: string) => `challenges/${gameSlug}`
    ),
    // Admin-only routes -------------------------------------------------------
    // Game variants
    VariantsList: Route.make<GameVariant[]>('admin/variants'),
    VariantCreate: Route.make<GameVariant, Partial<GameVariant>>(
        'admin/variants',
        HttpVerb.POST_JSON
    ),
    VariantUpdate: Route.make<GameVariant, Partial<GameVariant>, [number]>(
        (variantId: number) => `admin/variants/${variantId.toString(10)}`,
        HttpVerb.PATCH
    ),
    VariantDelete: Route.make<GameVariant[], null, [number]>(
        (variantId: number) => `admin/variants/${variantId.toString(10)}`,
        HttpVerb.DELETE
    ),
    // Users with special roles
    UserRoleList: Route.make<UserRoleMapping[]>('admin/users'),
    UserRoleCreate: Route.make<UserRoleMapping[], UserRoleMapping>(
        'admin/users',
        HttpVerb.POST_JSON
    ),
    UserRoleUpdate: Route.make<UserRoleMapping[], UserRoleMapping, [number]>(
        (mappingId: number) => `admin/users/${mappingId.toString(10)}`,
        HttpVerb.PUT
    ),
    UserRoleDelete: Route.make<null, null, [number]>(
        (mappingId: number) => `admin/users/${mappingId.toString(10)}`,
        HttpVerb.DELETE
    ),
    // User generated challenges
    UserChallenges: Route.make<UserChallenge[]>('admin/userchallenges'),
    // Scoreboard
    Scoreboard: Route.make<Score[]>('admin/scoreboard'),
};
