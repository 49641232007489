import type { TimerRootElement } from '../global';
import type { Duration } from 'dayjs/plugin/duration';

import dayjs, { Dayjs } from 'dayjs';
import { debug, DebugHelper } from '@gebruederheitz/debuggable';
import duration from 'dayjs/plugin/duration';

import { GameApi, Team } from 'urban-challenger-sdk';
import { localize } from '../util/i18n/localize';

dayjs.extend(duration);

interface UserOptions {
    format?: string;
    updateInterval?: number;
}

const DEFAULT_FORMAT = 'H:mm:ss';
const DEFAULT_INTERVAL = 200;

export class GameTimer {
    private debug: DebugHelper = debug.spawn(this);

    protected format: string = DEFAULT_FORMAT;
    protected updateInterval: number = DEFAULT_INTERVAL;

    protected paused: boolean = false;
    protected timeout: number | null = null;

    protected timeStarted: Dayjs | null = null;
    protected endTime: Dayjs | null = null;

    protected diffGetter: () => Duration | null = this.getRemaining;
    protected isFinished: boolean = false;

    protected initialized: boolean = false;
    protected team: Team | null = null;

    constructor(
        protected rootElement: TimerRootElement,
        protected sdk: GameApi,
        userOptions: UserOptions = {}
    ) {
        if (userOptions.updateInterval) {
            this.updateInterval = userOptions.updateInterval;
        }

        if (userOptions.format) {
            this.format = userOptions.format;
        }

        this.bootstrap(rootElement).then();
    }

    protected async bootstrap(rootElement: TimerRootElement): Promise<void> {
        if (!(await this.sdk.isLoggedIn())) {
            return;
        }

        if (rootElement.dataset.timerFormat) {
            this.format = rootElement.dataset.timerFormat;
        }

        this.sdk.subscribe((state) => {
            this.debug.log('state change!', state.team);
            const team = state.team;

            if (team === this.team) {
                this.debug.log('team unchanged.');

                return;
            }

            if (!team || !team.starttime) {
                this.debug.log('no team in new state');

                if (this.initialized) {
                    this.debug.log('resetting');
                    this.reset();
                }

                return;
            }

            this.team = team;

            this.timeStarted = dayjs(team.starttime);

            if (team.endtime) {
                this.endTime = dayjs(team.endtime);
                this.diffGetter = this.getRemaining;
            } else {
                this.diffGetter = this.getElapsed;
            }

            this.update();
            if (!this.initialized) {
                this.initialized = true;
                this.rootElement.classList.add('initialized');
                this.tick();
            }
        });
    }

    public pause() {
        this.paused = true;
        if (this.timeout) {
            clearInterval(this.timeout);
        }
    }

    public resume() {
        this.paused = false;
        this.tick();
    }

    public reset() {
        if (this.timeout) {
            clearInterval(this.timeout);
        }

        this.rootElement.classList.remove('initialized', 'finished');

        this.endTime = null;
        this.timeStarted = null;
        this.isFinished = false;
        this.paused = false;
        this.initialized = false;
    }

    protected tick(): void {
        this.debug.log('tick');
        this.timeout = window.setInterval(() => {
            if (!this.paused) {
                this.update();
            }
        }, this.updateInterval);
    }

    protected update() {
        const previousValue = this.rootElement.innerText;
        const elapsedOrRemaining = this.diffGetter();

        if (this.isFinished) {
            this.rootElement.classList.add('finished');
        }

        if (elapsedOrRemaining === null) {
            this.rootElement.innerText = dayjs
                .duration('PT0S')
                .format(this.format);
            this.paused = true;
            return;
        }

        let format = this.format;

        if (elapsedOrRemaining.days() > 0) {
            const daysLabel = localize('generic.days');
            format = `D [${daysLabel},] ${format}`;
        }

        const elapsedString = elapsedOrRemaining.format(format);
        if (elapsedString !== previousValue) {
            this.rootElement.innerText = elapsedString;
        }
    }

    protected getRemaining(): Duration | null {
        if (this.timeStarted) {
            const endTime: Dayjs = this.endTime;
            const now = dayjs();
            if (endTime < now) {
                this.isFinished = true;
                return null;
            }
            return dayjs.duration(endTime.diff(now));
        }

        return null;
    }

    protected getElapsed(): Duration | null {
        if (this.timeStarted) {
            const now = dayjs();
            return dayjs.duration(now.diff(this.timeStarted));
        }

        return null;
    }
}
