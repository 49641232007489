import type { ApiState, GameApi } from 'urban-challenger-sdk';

import { EnhancedElement } from '@gebruederheitz/energize';

import { confirm } from '../util/confirm';

export class EndGameButton extends EnhancedElement<HTMLElement, GameApi> {
    protected ownsGame: boolean = false;

    constructor(e) {
        super(e);
        this.hide();
        this.on('click', this.onClick);
    }

    protected override onStateUpdate({ ownsGame }: ApiState) {
        if (ownsGame !== this.ownsGame) {
            this.ownsGame = ownsGame;

            if (ownsGame && !this.visible) {
                this.show();
            } else if (!ownsGame && this.visible) {
                this.hide();
            }
        }
    }

    protected onClick = async (e: MouseEvent): Promise<void> => {
        e.preventDefault();

        if (await confirm('confirmation.owner.game.end', 'warning')) {
            await this.store.endAndArchiveGame();
        }
    };
}
