import type { GameApi, Team } from 'urban-challenger-sdk';

import { UserStatus } from 'urban-challenger-sdk';
import { TeamInvitation } from '../team-invitation';
import { EnhancedElement } from '@gebruederheitz/energize';

import { BaseLobby } from './base';

enum LobbyType {
    DEFAULT,
    LEADER,
    SINGLE,
}

export class TeamLobby extends BaseLobby {
    protected leaderElements: EnhancedElement<HTMLElement>[];
    protected noLeaderElements: EnhancedElement<HTMLElement>[];
    protected singlePlayerElements: EnhancedElement<HTMLElement>[];
    protected multiplayerElements: EnhancedElement<HTMLElement>[];
    protected teamCodeField: EnhancedElement<HTMLElement> | null;

    protected lobbyType: LobbyType;
    protected previousUserState: UserStatus = UserStatus.INITIAL;
    protected unsubscribe: () => void = () => {};
    protected invitation?: TeamInvitation;

    constructor(protected rootElement: HTMLElement, protected sdk: GameApi) {
        super(rootElement, sdk);
        this.leaderElements = Array.from(
            this.rootElement.querySelectorAll<HTMLElement>(
                '[data-game-leader="true"]'
            )
        ).map((element) => new EnhancedElement<HTMLElement>(element));

        this.noLeaderElements = Array.from(
            this.rootElement.querySelectorAll<HTMLElement>(
                '[data-game-leader="false"]'
            )
        ).map((element) => new EnhancedElement<HTMLElement>(element));

        this.singlePlayerElements = EnhancedElement.fromSelectorAll(
            '[data-game-leader="single"]',
            this.rootElement
        );

        this.multiplayerElements = EnhancedElement.fromSelectorAll(
            '[data-game-leader="!single"]',
            this.rootElement
        );

        this.teamCodeField = EnhancedElement.fromSelector(
            '[data-game-component="team-code"]',
            this.rootElement
        );
    }

    public override show(): void {
        super.show();

        switch (this.lobbyType) {
            // case LobbyType.SINGLE:
            case LobbyType.LEADER:
                this.multiplayerElements.forEach((e) => e.show());
                this.leaderElements.forEach((e) => e.show());
                this.noLeaderElements.forEach((e) => e.hide());
                this.singlePlayerElements.forEach((e) => e.hide());
                break;
            case LobbyType.SINGLE:
                this.multiplayerElements.forEach((e) => e.hide());
                this.leaderElements.forEach((e) => e.hide());
                this.noLeaderElements.forEach((e) => e.hide());
                this.singlePlayerElements.forEach((e) => e.show());
                break;
            case LobbyType.DEFAULT:
            default:
                this.multiplayerElements.forEach((e) => e.show());
                this.leaderElements.forEach((e) => e.hide());
                this.noLeaderElements.forEach((e) => e.show());
                this.singlePlayerElements.forEach((e) => e.hide());
        }

        this.unsubscribe = this.sdk.subscribe(this.onStateUpdate);
    }

    public override hide(): void {
        super.hide();
        this.unsubscribe();
        this.leaderElements.forEach((e) => e.hide());
        this.noLeaderElements.forEach((e) => e.hide());
        this.singlePlayerElements.forEach((e) => e.hide());
        this.multiplayerElements.forEach((e) => e.hide());
        this.reset();
    }

    protected onStateUpdate = ({
        status,
        team = null,
        maxDevices,
        playerCount,
    }) => {
        if (
            status !== this.previousUserState ||
            (this.teamCodeField &&
                team?.name !== this.teamCodeField.getContent())
        ) {
            this.previousUserState = status;
            if (
                status === UserStatus.TEAM_LOBBY ||
                status === UserStatus.TEAM_LOBBY_LEADER
            ) {
                if (status === UserStatus.TEAM_LOBBY_LEADER) {
                    if (maxDevices === 1) {
                        this.lobbyType = LobbyType.SINGLE;
                    } else {
                        this.lobbyType = LobbyType.LEADER;
                    }
                } else {
                    this.lobbyType = LobbyType.DEFAULT;
                }

                this.teamCodeField?.content(team.name || team.code);
                if (maxDevices > playerCount) {
                    this.renderInvitation(team);
                } else {
                    this.invitation?.hide();
                }
            } else {
                this.reset();
            }
        }
    };

    protected reset() {
        this.invitation?.destroy();
        this.invitation = null;
    }

    protected renderInvitation(team: Team) {
        if (team) {
            if (this.invitation) {
                this.invitation.show();
            } else {
                const specificContainer = this.rootElement.querySelector(
                    '[data-game-component="invitations"]'
                );
                const container = specificContainer || this.rootElement;
                this.invitation = TeamInvitation.fromTemplate(
                    [this.rootElement, document],
                    team,
                    this.sdk
                ).appendTo(container);
            }
        }
    }
}
