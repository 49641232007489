import type { ApiState, GameApi } from 'urban-challenger-sdk';
import type { Challenge } from './types';

import { debug } from '@gebruederheitz/debuggable';
import { EnhancedElement } from '@gebruederheitz/energize';

import { ChallengeSlideshow } from './index';

/**
 * Handles a control input for toggling as well as the actual filtering of the
 * challenge slideshow by completed status.
 *
 * @TODO: the input's state could be persisted to storage and initialized on load
 */
export class CompletedSlidesFilter extends EnhancedElement<
    HTMLInputElement,
    GameApi
> {
    protected readonly debug = debug.spawn(this);

    protected isActive: boolean = false;
    protected state: ApiState;

    public async init(slideshow: ChallengeSlideshow) {
        this.state = await this.store.getState();

        this.on('change', () => {
            this.debug.log('input change');
            this.isActive = this.element.checked;
            slideshow.forceUpdate();
        });
        this.isActive = this.element.checked;
        slideshow.addFilter(this.initSlideFilter);
    }

    protected initSlideFilter = (slides: Challenge[]): Challenge[] => {
        if (this.isActive) {
            slides = slides.filter((e) => {
                return !this.state.team.tasks[e.slug];
            });
        }

        return slides;
    };

    protected onStateUpdate(state: ApiState) {
        this.state = state;
    }
}
