import type { QrReader } from './qr-reader';
import type { GameApi } from 'urban-challenger-sdk';

import { debug } from '@gebruederheitz/debuggable';
import { toasts, UserStatus } from 'urban-challenger-sdk';
import { EnhancedElement } from '@gebruederheitz/energize';

import { localize } from '../util/i18n/localize';
import { confirm, confirmPrintf } from '../util/confirm';

export { WebQrReader } from './web-qr-reader';
export { NativelyQrReader } from './natively-qr-reader';

export class CodeReader<R extends QrReader> extends EnhancedElement<
    HTMLElement,
    GameApi
> {
    protected appUrls: string[] | null = null;
    protected readonly reader: R;
    private debug = debug.spawn(this);

    constructor(
        e: HTMLElement,
        protected readonly store: GameApi,
        QrReaderImplementation: new () => R
    ) {
        super(e);

        this.reader = new QrReaderImplementation();
        this.reader.onScanResult(this.onScanResult);
        this.on('click', () => {
            this.reader.show();
        });
    }

    protected onScanResult = async (result: string) => {
        this.debug.log('SCAN RESULT', result);
        const url = this.parseToUrl(result);

        let success = false;

        if (url && (await this.isValidAppUrl(url))) {
            success = await this.handleUrlResult(url);
        } else {
            success = !(await confirm('confirmation.qr.scan.invalid'));
        }

        if (!success) {
            this.reader.retry();
        } else {
            this.reader.stop();
        }
    };

    protected async handleUrlResult(url: URL): Promise<boolean> {
        if (url.searchParams.has('uc-game')) {
            // Game Claim Code
            const code = url.searchParams.get('uc-game');

            if (
                this.verifyCode(code, 'game') &&
                (await this.handleGameClaim(code))
            ) {
                return true;
            }
        } else if (url.searchParams.has('uc-team')) {
            // Team Join Code
            const code = url.searchParams.get('uc-team');

            if (
                this.verifyCode(code, 'team') &&
                (await this.handleTeamJoin(code))
            ) {
                return true;
            }
        } else if (url.searchParams.has('uc-voucher')) {
            /// Voucher Code
            const code = url.searchParams.get('uc-voucher');

            if (
                this.verifyCode(code, 'voucher') &&
                (await this.handleVoucher(code))
            ) {
                return true;
            }
        }
        //
        // What other QR codes are we likely to encounter?
        //
        if (url.pathname && url.pathname.startsWith('game')) {
            this.debug.log('Found a game link.');
            // redirect to game (deeplink?)
            window.location.replace(url.toString());
            return true;
        }

        return false;
    }

    protected verifyCode(
        code: string,
        type: 'game' | 'team' | 'voucher' | null = null
    ): boolean {
        debug.devnull(type);
        if (code.length !== 6) {
            toasts.make(localize('error.scan.qr.failed'), 'error');

            return false;
        }

        return true;
    }

    protected async handleGameClaim(code: string): Promise<boolean> {
        this.debug.log('claim game', code);
        this.reader.stop();

        if (
            await confirmPrintf('confirmation.claim.game', 'question', {
                '{{code}}': code,
            })
        ) {
            const userStatus = await this.store.claimGame(code);
            if (userStatus && userStatus > UserStatus.LOBBY) {
                // redirect to lobby page?
            }

            return userStatus !== null;
        }

        return false;
    }

    protected async handleTeamJoin(code: string): Promise<boolean> {
        this.debug.log('join team', code);
        this.reader.stop();

        if (
            await confirmPrintf('confirmation.join.team', 'question', {
                '{{code}}': code,
            })
        ) {
            const team = await this.store.joinTeam(code);
            if (team) {
                // redirect to lobby page?
            }

            return team !== null;
        }

        return false;
    }

    protected async handleVoucher(code: string): Promise<boolean> {
        this.debug.log('claim voucher', code);
        this.reader.stop();

        if (
            await confirmPrintf('confirmation.claim.voucher', 'question', {
                '{{code}}': code,
            })
        ) {
            const result = await this.store.claimVoucher(code);
            if (result) {
                // redirect to lobby page?
            }

            return result !== null;
        }

        return false;
    }

    protected parseToUrl(string: string): URL | null {
        let url;
        try {
            url = new URL(string);
        } catch (e) {
            return null;
        }

        return url;
    }

    protected async isValidAppUrl(url: URL): Promise<boolean> {
        if ((await this.getAppUrls()).indexOf(url.origin) > -1) {
            // if (url.searchParams.has('uc-game') || url.searchParams.has('uc-team')) {}

            return true;
        }

        return false;
    }

    protected async getAppUrls(): Promise<string[]> {
        if (!this.appUrls) {
            const { urls } = await this.store.getState();
            this.appUrls = [urls.base, urls.baseDeeplink];
        }

        return this.appUrls;
    }
}
