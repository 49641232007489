import type { Task } from 'urban-challenger-sdk';
import type { TaskStore } from './index';

import { ApiVersion, GameApi } from 'urban-challenger-sdk';
import { EnhancedElement } from '@gebruederheitz/energize';

export class ReviewComment extends EnhancedElement<HTMLElement, GameApi> {
    private display: EnhancedElement<HTMLElement> | null;
    private lastComment: string | null = null;
    private enabled: boolean = false;
    private previousApiVersion: ApiVersion = ApiVersion.Base;

    constructor(e, private taskStore: TaskStore) {
        super(e);
        this.display = this.findAndWrap(
            '[data-uc-task-component="comment-content"]'
        );
        this.taskStore.subscribe(this.onTaskStateUpdate);
    }

    public override content(innerText: string): this {
        this.display?.content(innerText);

        return this;
    }

    private updateComment(comment: string | null) {
        if (comment !== this.lastComment) {
            this.lastComment = comment;

            if (!comment) {
                this.hide().content('');
            } else {
                this.content(comment).show();
            }
        }
    }

    private onTaskStateUpdate = (task: Task | null) => {
        if (this.enabled) {
            this.updateComment(task?.comment || null);
        }
    };

    protected onStateUpdate() {
        if (this.store.version.get() !== this.previousApiVersion) {
            this.enabled = this.store.version.isAtLeast(
                ApiVersion.ReviewComments
            );
        }
    }
}
