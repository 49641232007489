import { JsonTeam, Team } from '../model/team';

export enum ModeratorStatus {
    INITIAL,
    UNIDENTIFIED,
    IDLE,
    GAME_ACTIVE,
}

export interface ModerationState {
    userId: string | null;
    ownsGame: boolean;
    userRole: null | 'user' | 'moderator' | 'admin';
    games: ModeratedGame[];
    urls?: {
        base: string;
        lobby: string;
        profile: string;
    };
    userStatus: ModeratorStatus;
    activeGames: number;
}

export const DEFAULT_MODERATION_STATE: ModerationState = {
    userId: null,
    ownsGame: false,
    userRole: null,
    urls: null,
    userStatus: ModeratorStatus.INITIAL,
    activeGames: 0,
    games: [],
};

export class ModeratedTask {
    public id: string | number;
    public slug: string;
    public points: number;
    public state: 'pending' | 'approved' | 'rejected';
    public text: string | null;
    public team: { name?: string | null; code: string };
    public file: string | null;

    public static fromJson(obj: Record<string, any>): ModeratedTask {
        return Object.assign(new ModeratedTask(), obj);
    }
}

export class ModeratedGame {
    id: number;
    owner: string | null;
    maxMembers: number;
    code: string;
    duration: number | null;
    fixedEnd: string | null;
    teams: Team[];
    queue: ModeratedTask[];
    resultsDeclared: boolean = false;
    createdAt: string = '';
    urls: {
        base: string;
        baseDeeplink: string;
        lobby: string;
        profile: string;
        redirect?: string | null;
    };
    variant: string;
    planId: string;
    comment?: string;
    alias?: string;

    public static fromJson(obj: Record<string, any>): ModeratedGame {
        if (obj.teams && obj.teams.length) {
            obj.teams = Team.fromJsonArray(obj.teams).sort((a, b) => {
                if (a.code[0] < b.code[0]) {
                    return -1;
                } else if (a.code[0] > b.code[0]) {
                    return 1;
                }
                return 0;
            });
        }
        return Object.assign(new ModeratedGame(), obj);
    }
}

export interface ModeratedGameResponseObject {
    id: number;
    owner: string | null;
    maxMembers: number;
    code: string;
    duration: number;
    teams: JsonTeam[];
    queue: ModeratedTask[];
    urls: {
        base: string;
        baseDeeplink: string;
        lobby: string;
        profile: string;
        redirect?: string | null;
    };
    resultsDeclared?: boolean;
    createdAt?: string;
    variant: string;
    planId: string;
    comment?: string;
    alias?: string;
}

export interface ModerationStatusResponseObject {
    id: string;
    role: 'user' | 'moderator' | 'admin';
    ownsGame?: boolean;
    urls: {
        base: string;
        baseDeeplink: string;
        lobby: string;
        profile: string;
        redirect?: string | null;
    };
    games: ModeratedGameResponseObject[];
    gameCount: number;
    version?: number;
}
