import { debug } from '@gebruederheitz/debuggable';

class UrlParameters {
    private debug = debug.spawn(this);
    private params = new URLSearchParams(window.location.search);

    constructor() {
        this.debug.log(this.params);
    }

    public async checkUrlForCode(
        parameterName: string,
        onMatchCallback: (code: string) => boolean | Promise<boolean>,
        isValidCallback: (code: string) => Promise<boolean> | null = null,
        codeLength: number = 6,
        exactLength: boolean = true
    ): Promise<boolean> {
        this.debug.log(
            'checking url for',
            parameterName,
            this.params.has(parameterName)
        );
        // const params = new URLSearchParams(window.location.search);
        // debug.log(params);

        if (this.params.has(parameterName)) {
            const code = this.params.get(parameterName);
            this.debug.log('found parameter', parameterName, code);

            const compareLength = exactLength
                ? (code) => code.length === codeLength
                : (code) => code.length >= codeLength;

            if (code && compareLength(code)) {
                this.debug.log(
                    'found a parameter of the right length',
                    parameterName,
                    code
                );
                if (!isValidCallback || (await isValidCallback(code))) {
                    if (await onMatchCallback(code)) {
                        this.removeParameter(parameterName);
                        return true;
                    }
                } else {
                    // Remove code parameter from URL
                    this.removeParameter(parameterName);
                }
            }
        }

        return false;
    }

    protected removeParameter(parameterName: string) {
        const url = new URL(window.location.href);
        url.searchParams.delete(parameterName);
        window.history.replaceState('remove parameter', null, url);
    }
}

const urlParameters = new UrlParameters();

export const checkUrlForCode =
    urlParameters.checkUrlForCode.bind(urlParameters);
