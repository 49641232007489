import type { GameApi } from 'urban-challenger-sdk';

import { EnhancedElement } from '@gebruederheitz/energize';

import { confirmWithSafety } from '../util/confirm';

export class DeleteAccount extends EnhancedElement<HTMLElement, GameApi> {
    constructor(element: HTMLElement) {
        super(element);
        this.on('click', this.onClick);
    }

    private onClick = async () => {
        if (await this.confirm()) {
            await this.store.deleteAccount();
            await window.$memberstackDom.logout();
            window.location.assign('/');
        }
    };

    private async confirm(): Promise<boolean> {
        return await confirmWithSafety('confirmation.account.delete');
    }
}
